import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { INPUT_IDS, ROLES, ROUTES } from "../constants";
import { displayNotificationModal } from "../store/slices/UiSlice";
import { texts } from "../assets/texts";
import { localStorageGet } from "../hooks/useLocalStorage";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { LayoutContext } from "../components/Layout/Layout";
import { deleteCompany, getCompanyById, updateCompanyComment } from "../store/thunks/companiesThunk";
import { RaterButton } from "../components/UI/RaterButton/RaterButton";
import { RaterInput } from "../components/UI/RaterInput/RaterInput";
import { theme } from "../assets/theme";
import { Company } from "../components/Company/Company";

const noCyrillicRegex: RegExp = /^[^а-яА-ЯёЁ]+$/;

const DeclineCompanySchema = yup.object({
    comment: yup.string().required(texts.add.requiredComment).matches(noCyrillicRegex, texts.add.noCyrillic),
}).required();

export const CompanyPage: React.FC = () => {
    let { companyId } = useParams();
    const navigate = useNavigate();
    const role = localStorageGet("role");
    const dispatch = useDispatch<AppDispatch>();
    const { loading, companyById } = useSelector((state: RootState) => state.companies);
    const isCompanyNew = companyById?.comments?.length === 1 && companyById.comments[0].draft;
    const { handleOpen, handleClose, open } = useContext(LayoutContext);

    const { control, formState: { errors, isValid }, reset } = useForm({
        resolver: yupResolver(DeclineCompanySchema),
        mode: "onBlur",
    });

    const initialDeclineCompanyFormValues = {
        comment: "",
    }

    const approveCompanyHandler = async () => {
        await dispatch(updateCompanyComment(companyById.comments[0])).then((res: any) => {
            if (res?.error) {
                dispatch(displayNotificationModal({
                    isOpen: true,
                    message: res?.error?.message,
                    messageType: 'error',
                }));
            } else {
                dispatch(displayNotificationModal({
                    isOpen: true,
                    message: texts.notifications.companyApproved,
                    messageType: 'success',
                }));
            }
        })
        if (companyById) {
            dispatch(getCompanyById(companyById.id))
        }
    }

    const handleDeclineCompanySubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const DeclineCompanyData = {
            id: companyById.id,
            message: data.get('comment'),
        };

        const initialDeleteCompanyFormValues = {
            comment: "",
        }

        dispatch(deleteCompany(DeclineCompanyData)).then((res: any) => {
            if (res?.error) {
                if (res?.error.message === texts.notifications.userUnauthorized) {
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: texts.notifications.refreshUnauthorized,
                        messageType: 'warning',
                    }));
                    navigate(ROUTES.signIn)
                } else {
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: res?.error?.message,
                        messageType: 'error',
                    }));
                }
            } else {
                dispatch(displayNotificationModal({
                    isOpen: true,
                    message: texts.notifications.companyDeclined,
                    messageType: 'success',
                }));
                reset(initialDeleteCompanyFormValues);
                handleClose();
                navigate(ROUTES.moderation);
            }
        })
    };

    useEffect(() => {
        if (companyId) dispatch(getCompanyById(companyId)).then((res: any) => {
            if (res?.error) {
                if (res?.error.message === texts.notifications.userUnauthorized) {
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: texts.notifications.refreshUnauthorized,
                        messageType: 'warning',
                    }));
                    navigate(ROUTES.signIn)
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const idDesktop = window.innerWidth > 992;

    return (
        <Box sx={{
            minHeight: "calc(100vh - 60px - 92px)",
            display: "flex",
        }}>
            {loading
                ? <CircularProgress color='inherit' />
                :
                <Box sx={{
                    display: "flex",
                    width: "100%",
                    flex: 1,
                    "@media screen and (max-width: 992px)": {
                        flexDirection: "column",
                    },
                }}>
                    <Company company={companyById} isCompanyNew={isCompanyNew} />
                    {isCompanyNew && role === ROLES.admin &&
                        <Box sx={{
                            padding: "40px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            "@media screen and (max-width: 992px)": {
                                padding: "0 40px",
                                flexDirection: "row",
                                justifyContent: "center",
                                flexWrap: "wrap",
                            },
                        }}
                        >
                            <Box sx={{
                                marginTop: "12px",
                                "@media screen and (max-width: 992px)": {
                                    marginTop: "0px",
                                },
                            }}>

                                <RaterButton
                                    size="small"
                                    clickHandler={() => approveCompanyHandler()}
                                    title={texts.companies.approveBtn}
                                    fullWidth={idDesktop}
                                    customStyles={{
                                        textTransform: "none",
                                        whiteSpace: "nowrap",
                                    }}
                                />
                            </Box>
                            <RaterButton
                                size="small"
                                clickHandler={handleOpen}
                                title={texts.companies.declineBtn}
                                fullWidth={idDesktop}
                                customStyles={{
                                    textTransform: "none",
                                    whiteSpace: "nowrap",
                                }}
                            />
                        </Box>
                    }
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{
                            ".MuiPaper-root": {
                                borderRadius: "15px",
                            }
                        }}
                    >
                        <Box
                            component="form"
                            onSubmit={handleDeclineCompanySubmit}
                        >
                            <DialogTitle id="alert-dialog-title" sx={{ mr: 2, fontSize: theme.font.size.regular }}>
                                {texts.notifications.whyDeclineCompany}
                            </DialogTitle>
                            <DialogContent>
                                <Controller
                                    name="comment"
                                    control={control}
                                    render={({ field }) =>
                                        <RaterInput
                                            {...field}
                                            type={INPUT_IDS.comment}
                                            placeholder={texts.companies.addExplanations}
                                            errorText={errors?.comment?.message}
                                            required
                                            customStyles={{
                                                minHeight: "40px",
                                            }}
                                        />
                                    }
                                />

                            </DialogContent>
                            <DialogActions sx={{ justifyContent: "center", marginBottom: "15px" }}>
                                <RaterButton size="small"
                                    type="submit"
                                    title={texts.companies.sendExplanations}
                                    disabled={!isValid}
                                />
                                <RaterButton
                                    size="small"
                                    clickHandler={() => {
                                        reset(initialDeclineCompanyFormValues);
                                        handleClose();
                                    }}
                                    title={texts.companies.closeModal}
                                />
                            </DialogActions>
                        </Box>
                    </Dialog >
                </Box>
            }
        </Box>
    )
}