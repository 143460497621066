import styled from "styled-components";
import { theme } from "../../assets/theme";

export const Wrapper = styled("div")({
  minHeight: "calc(100vh - 60px - 92px)",
  maxWidth: "1200px",  
  padding: "50px 0 30px 0",
});

export const AddCandidateContainer = styled("div")({
  backgroundColor: theme.bg.dark,
  borderRadius: "40px",
  padding: "40px",
});

