import styled from "styled-components";
import { theme } from "../../assets/theme";
import { Link } from "@mui/material";

export const CandidateContainer = styled("div")<{isCandidateNew?: boolean}>(({ isCandidateNew }) => ({
  display: "flex",
  justifyContent: "space-between",
  height: "100%",
  gap: "60px",
  padding: "40px 0",
  flex: 1,
  position: "relative",
  maxWidth: "1180px",
  "@media screen and (max-width: 992px)": {
    flexDirection: "column",
    flex: isCandidateNew ? 0 : 1,
    paddingBottom: isCandidateNew ? "20px": "40px",
  },
}));

export const CandidateMain = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  flex: 1,
});

export const CandidateInfo = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  gap: "15px",
});

export const CandidateInfoItem = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  background: theme.bg.second,
  borderRadius: "15px",
  padding: "15px",
  gap: "10px",
});

export const CandidateInfoLinks = styled(CandidateInfoItem)({
  width: "330px",
  flex: 1,
});

export const CandidateInfoParam = styled("div")({
  display: "flex",
  gap: "30px",
});

export const CandidateInfoItemTitle = styled("div")({
  fontWeight: 600,
  flexBasis: "100px",
  whiteSpace: "nowrap",
});

export const CandidateInfoItemLink = styled(Link)({
  whiteSpace: "nowrap",
});

export const CandidateInfoItemLinkContainer = styled(Link)({
  overflow: "hidden",
  textOverflow: "ellipsis",
});

export const CandidateRating = styled("div")({
  alignSelf: "center",
  flexBasis: "330px",
  position: "fixed",
  left: "50%",
  transform: "translateX(280px)",
  top: "22vh",
  "@media screen and (max-width: 1200px)": {
    left: "auto",
    right: "30px",
    transform: "translateX(0)",
  },
  "@media screen and (max-width: 992px)": {
    top: "auto",
    right: "auto",
    position: "relative",
  },
});
