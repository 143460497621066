import styled from "styled-components";
import { theme } from "../../assets/theme";

export const HeaderBg = styled("div")({
  backgroundColor: theme.bg.second,
});

export const HeaderContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  padding: "10px 10px",
  maxWidth: "1180px",
  margin: "0 auto",
  height: "60px",
  "@media screen and (max-width: 1180px)": {
    padding: "10px 30px",
    gap: "15px",
  },
});

export const HeaderLogo = styled("div")({
  display: "flex",
  alignItems: "center",
  height: "40px",
});
export const HeaderLogoIcon = styled("span")({
  height: "100%",
  img: {
    height: "100%",
  },
});

export const HeaderLogoTitle = styled("span")({
  color: "#124B74",
  fontSize: "44px",
  fontWeight: 700,
  letterSpacing: "-1px",
  transform: "translateY(2px)",
  marginLeft: "8px",
});

export const Tools = styled("div")({
  display: "flex",
  gap: "30px",
  alignItems: "center",
});

export const ToolsLinks = styled("div")({
  display: "flex",
  gap: "15px",
  fontSize: "18px",
  whiteSpace: "nowrap",
  "@media screen and (max-width: 992px)": {
    fontSize: "16px",
  },
  "@media screen and (max-width: 768px)": {
    fontSize: theme.font.size.small,
    gap: "10px",
  },
});

export const ToolsLogin = styled("div")({
  display: "flex",
  gap: "20px",
  alignItems: "center",
});

export const UserName = styled("div")({});

export const LogoutIcon = styled("div")({
  height: "40px",
  img: {
    height: "100%",
  },
  transition: "all 0.3s linear",
  "&:hover": {
    opacity: 0.7,
    cursor: "pointer",
    transform: "scale(1.1)",
    transition: "all 0.3s linear",
  },
});
