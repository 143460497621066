import { RaterInputProps } from "./types"
import { theme } from "../../../assets/theme"
import { INPUT_IDS, ROUTES } from "../../../constants"
import Box from "@mui/material/Box"
import { RaterInputError } from "./RaterInput.styles"
import { TextField } from "@mui/material"
import { useLocation } from "react-router"

export const RaterInput: React.FC<RaterInputProps> = (
    {
        type,
        placeholder,
        autoFocus,
        errorText,
        marginBottomValue,
        required,
        customStyles,
        handleOnFocus,
        small,
        ...rest
    }) => {

    const location = useLocation();

    return (
        <Box sx={{ marginBottom: marginBottomValue, position: "relative" }}>
            <TextField
                margin="normal"
                required={required}
                fullWidth
                type={type}
                placeholder={placeholder}
                name={type}
                autoComplete={type}
                autoFocus={autoFocus}
                variant="standard"
                multiline={(type === INPUT_IDS.comment || type === INPUT_IDS.stageDescription) && !handleOnFocus} //reason of one line teaxtarea
                {...rest}
                InputProps={{
                    disableUnderline: true,
                    rows: type === INPUT_IDS.comment ? 4 : 1,
                    onFocus: handleOnFocus,
                    style: {
                        fontSize: 20,
                        color: theme.font.color.second,
                        fontFamily: "'Inter', sans-serif",
                        padding: "10px",
                        height: small ? "40px" : "fit-content",
                        lineHeight: type === INPUT_IDS.comment ? 1.2 : (small ? "40px" : "50px"),
                    }
                }}
                sx={{
                    backgroundColor: theme.bg.second,
                    borderRadius: "15px",
                    margin: 0,
                    boxSizing: "border-box",
                    border: errorText ? `2px solid ${theme.errorInpurBorder}` : "2px solid transparent",
                    ...customStyles,
                }}
            />
            <RaterInputError light={location.pathname === ROUTES.signUp}>{errorText}</RaterInputError>
        </Box>
    )
}

