import Box from '@mui/material/Box';
import { GridColDef } from '@mui/x-data-grid';
import { texts } from '../../assets/texts';
import { RaterDataGrid } from '../RatedDataGrid/RaterDataGrid';
import { AppDispatch, RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { localStorageGet } from "../../hooks/useLocalStorage";
import { ROLES, ROUTES, TABLE_TYPES } from '../../constants';
import { DeleteButton } from '../UI/DeleteButton/DeleteButton';
import { RaterRatingProgress } from '../RaterRating/RaterRating.styles';
import { formateDate } from '../../helpers/formateDate';
import { getAllCompanies } from '../../store/thunks/companiesThunk';
import { CompanyCommentProps, CompanyProps } from '../../types/companies.types';
import { calculateCompanyTotalRatingFromCommentsList } from '../../helpers/calculateRating';
import { displayNotificationModal } from '../../store/slices/UiSlice';
import { useNavigate } from 'react-router-dom';

const columns: GridColDef[] = [
    {
        field: 'name',
        headerName: texts.admin.table.name,
        minWidth: 130,
    },
    {
        field: 'linkedinUrl',
        headerName: texts.admin.table.linkedIn,
        minWidth: 130,
        flex: 1,
    },
    {
        field: 'siteUrl',
        headerName: texts.admin.table.siteUrl,
        minWidth: 200,
    },
    {
        field: 'comment',
        headerName: texts.admin.table.comment,
        flex: 1,
        minWidth: 120,
    },
    {
        field: 'rating',
        headerName: texts.admin.table.rating,
        minWidth: 150,
        renderCell: (params) => (
            <RaterRatingProgress
                name="common-company-rating"
                value={params.value}
                readOnly
                precision={0.5}
            />
        )
    },
    {
        field: 'createdAt',
        headerName: texts.admin.table.createdDate,
        minWidth: 100,
        renderCell: (params) => {
            return (
                <div>{formateDate(params.value)}</div>
            )
        }
    },
    {
        field: 'remove',
        headerName: '',
        width: 60,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => {
            return (
                <>
                    {localStorageGet("role") === ROLES.admin &&
                        <DeleteButton id={String(params.id)} />
                    }
                </>

            )
        }
    },
];

const createTable = (data: CompanyProps[], moderation: boolean) => {
    let filteredCompanies = data;
    if (moderation) {
        filteredCompanies = data?.length ? data.filter((item) => {
            const hasCommentsForModeration = item.comments.some((comment) => comment.draft)
            return hasCommentsForModeration
        }) : [];
    }

    return filteredCompanies?.length ? filteredCompanies.map(item => {
        const { id, companyName, linkedinUrl, createdAt, siteUrl, comments } = item;
        let commentsList = Object.assign([] as any[], comments);
        const moderatedComments = commentsList.filter((comment: CompanyCommentProps) => !comment.draft);

        if (moderatedComments.length > 1) {
            moderatedComments.sort((a: CompanyCommentProps, b: CompanyCommentProps) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
        }

        return ({
            id,
            name: companyName,
            linkedinUrl,
            siteUrl,
            rating: calculateCompanyTotalRatingFromCommentsList(moderatedComments) || 0,
            createdAt,
            comment: moderatedComments.length ? moderatedComments[0].text : commentsList[commentsList.length - 1].text,
        })
    }) : []
};

export const CompaniesTable: React.FC<{ moderation?: boolean | undefined }> = ({ moderation }) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { companies, loading } = useSelector((state: RootState) => state.companies);
    const role = localStorageGet("role");
    let filteredCompanies: CompanyProps[] = [];

    if (companies) {
        if (role !== ROLES.admin) {
            if (companies.length) {
                filteredCompanies = companies?.filter((company) => {
                    const isCompanyNew = company?.comments?.length === 1 && company.comments && company.comments[0].draft;
                    return !isCompanyNew;
                })
            }
        } else {
            filteredCompanies = companies;
        }
    }

    useEffect(() => {
        dispatch(getAllCompanies()).then((res: any) => {
            if (res?.error) {
                if (res?.error.message === texts.notifications.userUnauthorized) {
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: texts.notifications.refreshUnauthorized,
                        messageType: 'warning',
                    }));
                    navigate(ROUTES.signIn)
                }
            }
        });
    }, []);

    return (
        <Box sx={{
            height: 347,
            width: '100%',
        }}>
            {loading
                ? <CircularProgress color='inherit' />
                : <RaterDataGrid rows={createTable(filteredCompanies, !!moderation)} columns={columns} tableType={TABLE_TYPES.companies} moderation={moderation} />
            }
        </Box>
    );
}
