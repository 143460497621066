import { texts } from "../../assets/texts"
import { CandidatesTitle } from "./CandidateList.styles"
import { CandidateTable } from "./CandidateTable"
import { CandidateListProps } from "./types"

export const CandidateList: React.FC<CandidateListProps> = ({ moderation, title }) => {
    return (
        <div>
            {title && <CandidatesTitle>{texts.candidateList.moderationTitle}</CandidatesTitle>}
            <CandidateTable moderation={moderation} />
        </div>
    )
}