import { AlertColor } from "@mui/material/Alert";
import { createSlice } from "@reduxjs/toolkit";

export interface IStore {
  showNotificationModal: {
    isOpen: boolean,
    messageType: AlertColor | '';
    message: string;
  };
}

const initialState: IStore = {
  showNotificationModal: {
    isOpen: false,
    messageType: '',
    message: '',
  },
};

const UiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    displayNotificationModal: (state, action) => {
      state.showNotificationModal = action.payload;
    },
  },
});

export const { displayNotificationModal } = UiSlice.actions;

export default UiSlice.reducer;