import Link from "@mui/material/Link";
import { SignContainer, SignFormContainer, SignFormTitle, SignLink, SignTabs } from "./Sign.styles"
import { SignProps } from "./types"
import { Link as RouterLink } from "react-router-dom";
import { ROUTES, SIGN_TYPES } from "../../constants";
import { texts } from "../../assets/texts";
import { theme } from "../../assets/theme";

export const Sign: React.FC<SignProps> = ({ type, children }) => {
    return (
        <SignContainer>
            <SignTabs type={type}>
                <SignLink type={type}>
                    <Link component={RouterLink} to={type === SIGN_TYPES.IN ? ROUTES.signUp : ROUTES.signIn}
                        sx={{
                            color: type === SIGN_TYPES.IN ? theme.font.color.light : theme.font.color.main,
                            textDecorationColor: type === SIGN_TYPES.IN ? theme.font.color.light : theme.font.color.main,
                            transition: "all 0.3s linear",
                            "&:hover": {
                                color: theme.font.color.active,
                                textDecorationColor: "transparent",
                            }
                        }}>
                        {type === SIGN_TYPES.IN ? texts.sign.signUp : texts.sign.signIn}
                    </Link>
                </SignLink>
                <SignFormContainer type={type}>
                    <SignFormTitle type={type}>
                        {type === SIGN_TYPES.IN ? texts.sign.signIn : texts.sign.signUp}
                    </SignFormTitle>
                    {children}
                </SignFormContainer>
            </SignTabs>
        </SignContainer>
    )
}