import Box from "@mui/material/Box";
import { texts } from "../../../assets/texts";
import { INPUT_IDS, ROUTES } from "../../../constants";
import { RaterInput } from "../../UI/RaterInput/RaterInput";
import { RaterButton } from "../../UI/RaterButton/RaterButton";
import { Link as RouterLink , useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import { loginUser } from "../../../store/thunks/loginThunk";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { displayNotificationModal } from "../../../store/slices/UiSlice";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { theme } from "../../../assets/theme";

const SignInSchema = yup.object({
    email: yup.string().required(texts.add.requiredEmail).email(texts.add.invalidEmail),
    password: yup.string()
        .required(texts.add.requiredPassword)
        .min(6, texts.add.invalidPasswordMin)
        .matches(/[0-9]/, texts.add.invalidPasswordNumber)
        .matches(/[a-z]/, texts.add.invalidPasswordLowercase)
        .matches(/[A-Z]/, texts.add.invalidPasswordUppercase)
}).required();

export const SignInForm: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const { control, formState: { errors, isValid } } = useForm({
        resolver: yupResolver(SignInSchema),
        mode: "onBlur",
    });

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const loginData = {
            email: data.get('email'),
            password: data.get('password'),
        };
        dispatch(loginUser({ email: loginData.email, password: loginData.password }))
            .then((res: any) => {
                if (res?.error) {
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: res?.error?.message,
                        messageType: 'error',
                    }));
                } else {
                    navigate(ROUTES.home);
                }
            });
    };

    return (
        <Box component="form"
            onSubmit={handleSubmit}
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                height: "100%",
            }}
        >
            <Controller
                name="email"
                control={control}
                render={({ field }) =>
                    <RaterInput
                        {...field}
                        type={INPUT_IDS.email}
                        placeholder={texts.sign.email}
                        errorText={errors?.email?.message}
                        marginBottomValue="48px"
                        required
                        autoFocus
                    />
                }
            />
            <Controller
                name="password"
                control={control}
                render={({ field }) =>
                    <RaterInput
                        {...field}
                        type={INPUT_IDS.password}
                        placeholder={texts.sign.password}
                        errorText={errors?.password?.message}
                        marginBottomValue="20px"
                        required
                    />
                }
            />
            <Link component={RouterLink} to={ROUTES.resetPassEmail}
                sx={{
                    color: theme.font.color.second,
                    textDecorationColor: theme.font.color.second,
                    transition: "all 0.3s linear",
                    fontSize: "12px",
                    opacity: .5,
                    marginBottom: "25px",
                    textAlign: "center",
                    "&:hover": {
                        textDecorationColor: "transparent",
                        opacity: 1,
                    }
                }}>
                {texts.sign.forgotPass}
            </Link>
            <Box sx={{ margin: "0 auto" }}>
                <RaterButton
                    title={texts.sign.submit}
                    disabled={!isValid}
                    type="submit"
                />
            </Box>
        </Box>
    )
}