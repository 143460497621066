import { Box } from "@mui/material"
import { CandidateList } from "../components/CandidateList/CandidateList"
import { CompaniesList } from "../components/CompaniesList/CompaniesList"
import { texts } from "../assets/texts"

export const Moderation: React.FC = () => {
    return (
        <Box sx={{
            minHeight: "calc(100vh - 60px - 92px)",
            display: "flex",
            flexDirection: "column",
            paddingTop: "50px",
            "@media screen and (max-width: 768px)": {
                paddingTop: "60px",
            },
        }}>
            <CandidateList moderation title={texts.candidateList.moderationTitle}/>
            <CompaniesList moderation title={texts.companies.moderationTitle}/>
        </Box>
    )
}