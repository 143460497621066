import Box from "@mui/material/Box"
import { AbouUsComponent } from "../components/AbouUsComponent/AbouUsComponent"

export const About: React.FC = () => {
    return (
        <Box sx={{
            minHeight: "calc(100vh - 60px - 92px)",
            display: "flex",
        }}>
            <AbouUsComponent />
        </Box>
    )
}