import { DataGrid, GridEventListener } from "@mui/x-data-grid"
import { theme } from "../../assets/theme"
import { RaterDataGridProps } from "./types"
import { useContext } from "react";
import { texts } from "../../assets/texts";
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { RaterButton } from "../UI/RaterButton/RaterButton";
import { useNavigate } from "react-router";
import { INPUT_IDS, ROLES, ROUTES, TABLE_TYPES } from "../../constants";
import { LayoutContext } from "../Layout/Layout";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { deleteCandidate, getAllCandidates } from "../../store/thunks/candidateThunk";
import { displayNotificationModal } from "../../store/slices/UiSlice";
import { deleteUser, getAllUsers } from "../../store/thunks/loginThunk";
import { localStorageGet } from "../../hooks/useLocalStorage";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { RaterInput } from "../UI/RaterInput/RaterInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { deleteCompany } from "../../store/thunks/companiesThunk";

const noCyrillicRegex: RegExp = /^[^а-яА-ЯёЁ]+$/;

const DeclineCandidateSchema = yup.object({
    comment: yup.string().required(texts.add.requiredComment).matches(noCyrillicRegex, texts.add.noCyrillic),
}).required();

export const RaterDataGrid: React.FC<RaterDataGridProps> = ({ rows, columns, tableType, moderation }) => {
    const navigate = useNavigate();
    const { open, handleClose, idToDelete } = useContext(LayoutContext);
    const dispatch = useDispatch<AppDispatch>();
    const role = localStorageGet("role");

    const { control, formState: { errors, isValid }, reset } = useForm({
        resolver: yupResolver(DeclineCandidateSchema),
        mode: "onBlur",
    });

    const initialDeclineCandidateFormValues = {
        comment: "",
    }

    const initialDeleteCompanyFormValues = {
        comment: "",
    }

    const handleEvent: GridEventListener<'rowClick'> = async (
        params, // GridRowParams
        event, // MuiEvent<React.MouseEvent<HTMLElement>>
        details, // GridCallbackDetails
    ) => {
        event.stopPropagation();
        switch (tableType) {
            case TABLE_TYPES.candidates:
                navigate(`${ROUTES.candidate}/${params.row.id}`);
                break;
            case TABLE_TYPES.companies:
                navigate(`${ROUTES.company}/${params.row.id}`);
                break;
        }
    };

    const handleDeleteCandidate = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const DeclineCandidateData = {
            id: idToDelete,
            message: data.get('comment'),
        };

        dispatch(deleteCandidate(DeclineCandidateData)).then((res: any) => {
            if (res?.error) {
                if (res?.error.message === texts.notifications.userUnauthorized) {
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: texts.notifications.refreshUnauthorized,
                        messageType: 'warning',
                    }));
                    navigate(ROUTES.signIn)
                } else {
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: res?.error?.message,
                        messageType: 'error',
                    }));
                }
            } else {
                dispatch(displayNotificationModal({
                    isOpen: true,
                    message: texts.notifications.candidateDeclined,
                    messageType: 'success',
                }));
                reset(initialDeclineCandidateFormValues);
                handleClose();

                navigate(ROUTES.moderation);
            }
        })
    };

    const handleDeleteCompany = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const DeclineCompanyData = {
            id: idToDelete,
            message: data.get('comment'),
        };

        dispatch(deleteCompany(DeclineCompanyData)).then((res: any) => {
            if (res?.error) {
                if (res?.error.message === texts.notifications.userUnauthorized) {
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: texts.notifications.refreshUnauthorized,
                        messageType: 'warning',
                    }));
                    navigate(ROUTES.signIn)
                } else {
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: res?.error?.message,
                        messageType: 'error',
                    }));
                }
            } else {
                dispatch(displayNotificationModal({
                    isOpen: true,
                    message: texts.notifications.companyDeleted,
                    messageType: 'success',
                }));
                reset(initialDeleteCompanyFormValues);
                handleClose();

                navigate(ROUTES.moderation);
            }
        })
    };

    const handleRemove = async () => {
        dispatch(deleteUser(idToDelete))
            .then((res: any) => {
                if (res?.error) {
                    if (res?.error.message === texts.notifications.userUnauthorized) {
                        dispatch(displayNotificationModal({
                            isOpen: true,
                            message: texts.notifications.refreshUnauthorized,
                            messageType: 'warning',
                        }));
                        navigate(ROUTES.signIn)
                    } else {
                        dispatch(displayNotificationModal({
                            isOpen: true,
                            message: res?.error?.message,
                            messageType: 'error',
                        }));
                    }
                } else {
                    if (tableType === TABLE_TYPES.candidates) {
                        dispatch(getAllCandidates());
                    } else {
                        dispatch(getAllUsers());
                    }

                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: tableType === TABLE_TYPES.candidates ? texts.notifications.candidateDeleted : texts.notifications.userDeleted,
                        messageType: 'success',
                    }));
                }
            })
        handleClose();
    };

    return (
        <>
            <DataGrid
                rows={rows}
                columns={columns}
                hideFooterSelectedRowCount
                disableRowSelectionOnClick={tableType === TABLE_TYPES.users}
                onRowClick={handleEvent}
                columnVisibilityModel={{
                    remove: !moderation ? role === ROLES.admin : false,
                    linkedinUrl: false,
                    rating: !moderation,
                    createdAt: tableType !== TABLE_TYPES.users
                }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'createdAt', sort: 'desc' }],
                    },
                    pagination: {
                        paginationModel: {
                            pageSize: 3,
                        },
                    },
                }}
                pageSizeOptions={[3]}
                autoHeight
                sx={{
                    border: "none",
                    borderRadius: "40px",
                    backgroundColor: theme.bg.second,
                    padding: "10px 20px",
                    ".MuiDataGrid-withBorderColor": {
                        borderColor: "#000000",
                    },
                    ".MuiDataGrid-columnHeaderTitle": {
                        fontWeight: 600,
                    },
                    ".MuiDataGrid-cell": {
                        "&:focus": {
                            outline: "none",
                        },
                        "&:hover": {
                            cursor: tableType === TABLE_TYPES.candidates || tableType === TABLE_TYPES.companies ? "pointer" : "auto",
                        }
                    },
                }}
            />
            {tableType === TABLE_TYPES.candidates &&
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{
                        ".MuiPaper-root": {
                            borderRadius: "15px",
                        }
                    }}
                >
                    <Box
                        component="form"
                        onSubmit={handleDeleteCandidate}
                    >
                        <DialogTitle id="alert-dialog-title" sx={{ mr: 2, fontSize: theme.font.size.regular }}>
                            {texts.candidate.moderation.whyDeleteCandidate}
                        </DialogTitle>
                        <DialogContent>
                            <Controller
                                name="comment"
                                control={control}
                                render={({ field }) =>
                                    <RaterInput
                                        {...field}
                                        type={INPUT_IDS.comment}
                                        placeholder={texts.candidate.moderation.addExplanations}
                                        errorText={errors?.comment?.message}
                                        required
                                        customStyles={{
                                            minHeight: "40px",
                                        }}
                                    />
                                }
                            />

                        </DialogContent>
                        <DialogActions sx={{ justifyContent: "center", marginBottom: "15px" }}>
                            <RaterButton size="small"
                                type="submit"
                                title={texts.candidate.moderation.sendExplanations}
                                disabled={!isValid}
                            />
                            <RaterButton
                                size="small"
                                clickHandler={() => {
                                    reset(initialDeclineCandidateFormValues);
                                    handleClose();
                                }}
                                title={texts.candidate.moderation.closeModal}
                            />
                        </DialogActions>
                    </Box>
                </Dialog >
            }
            {tableType === TABLE_TYPES.companies &&
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{
                        ".MuiPaper-root": {
                            borderRadius: "15px",
                        }
                    }}
                >
                    <Box
                        component="form"
                        onSubmit={handleDeleteCompany}
                    >
                        <DialogTitle id="alert-dialog-title" sx={{ mr: 2, fontSize: theme.font.size.regular }}>
                            {texts.notifications.whyDeleteCompany}
                        </DialogTitle>
                        <DialogContent>
                            <Controller
                                name="comment"
                                control={control}
                                render={({ field }) =>
                                    <RaterInput
                                        {...field}
                                        type={INPUT_IDS.comment}
                                        placeholder={texts.companies.addExplanations}
                                        errorText={errors?.comment?.message}
                                        required
                                        customStyles={{
                                            minHeight: "40px",
                                        }}
                                    />
                                }
                            />

                        </DialogContent>
                        <DialogActions sx={{ justifyContent: "center", marginBottom: "15px" }}>
                            <RaterButton size="small"
                                type="submit"
                                title={texts.companies.sendExplanations}
                                disabled={!isValid}
                            />
                            <RaterButton
                                size="small"
                                clickHandler={() => {
                                    reset(initialDeclineCandidateFormValues);
                                    handleClose();
                                }}
                                title={texts.companies.closeModal}
                            />
                        </DialogActions>
                    </Box>
                </Dialog >
            }
            {tableType === TABLE_TYPES.users &&
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{
                        ".MuiPaper-root": {
                            borderRadius: "15px",
                        }
                    }}
                >
                    <DialogTitle id="alert-dialog-title" sx={{ mr: 2, fontSize: theme.font.size.regular }}>
                        {tableType === TABLE_TYPES.candidates ? texts.candidateList.question : texts.userList.question}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {tableType === TABLE_TYPES.candidates ? texts.candidateList.explanations : texts.userList.explanations}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: "center", marginBottom: "15px" }}>
                        <RaterButton size="small" clickHandler={handleRemove} title={tableType === TABLE_TYPES.candidates ? texts.candidateList.removeBtn : texts.userList.removeBtn} />
                        <RaterButton size="small" clickHandler={handleClose} title={tableType === TABLE_TYPES.candidates ? texts.candidateList.cancelBtn : texts.userList.cancelBtn} />
                    </DialogActions>
                </Dialog>
            }
        </>
    )
}
