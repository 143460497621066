import AbousUs from "../../assets/images/about-us-transparent.png"
import { texts } from "../../assets/texts"
import { AboutUsImg, AboutUsText } from "./AbouUsComponent.styles"
import Box from "@mui/material/Box"

export const AbouUsComponent: React.FC = () => {
    return (
        <Box sx={{
            display: "grid",
            gridGap: "6vw",
            gridTemplateColumns: "1fr 1fr",
            p: 8,
            "@media screen and (max-width: 992px)": {
                gridTemplateColumns: "auto",
                padding: "40px",
                ".about-img": {
                    gridRow: 1,
                    margin: "0 auto",
                }
            },
        }}>
            <AboutUsText>
                {texts.aboutUs}
            </AboutUsText>
            <AboutUsImg className="about-img">
                <img src={AbousUs} alt="X letter" />
            </AboutUsImg>
        </Box>
    )
}