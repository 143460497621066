import Box from '@mui/material/Box';
import { GridColDef } from '@mui/x-data-grid';
import { texts } from '../../assets/texts';
import { RaterDataGrid } from '../RatedDataGrid/RaterDataGrid';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { getAllUsers } from '../../store/thunks/loginThunk';
import { useEffect } from 'react';
import { IManager } from '../../types/user.types';
import CircularProgress from '@mui/material/CircularProgress';
import { DeleteButton } from '../UI/DeleteButton/DeleteButton';
import { ROUTES, TABLE_TYPES } from '../../constants';
import { displayNotificationModal } from '../../store/slices/UiSlice';
import { useNavigate } from 'react-router-dom';

const columns: GridColDef[] = [
    {
        field: 'createdAt',
        headerName: "",
        minWidth: 200,
    },
    {
        field: 'name',
        headerName: texts.admin.table.name,
        width: 250,
    },
    {
        field: 'email',
        headerName: texts.admin.table.email,
        flex: 1,
        minWidth: 200,
    },
    {
        field: 'remove',
        headerName: '',
        width: 60,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => {
            return (
                <DeleteButton id={String(params.id)} />
            )
        }
    },
];

const createTable = (data: IManager[]) => data?.length ? data.map(item => ({
    id: item.id,
    name: item.name,
    email: item.email,
    createdAt: item.createdAt,
})) : [];

export const UsersTable = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { users, loading } = useSelector((state: RootState) => state.users);

    useEffect(() => {
        dispatch(getAllUsers()).then((res: any) => {
            if (res?.error) {
                if (res?.error.message === texts.notifications.userUnauthorized) {
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: texts.notifications.refreshUnauthorized,
                        messageType: 'warning',
                    }));
                    navigate(ROUTES.signIn)
                }
            }
        });
    }, []);

    return (
        <Box sx={{
            height: 347,
            width: '100%',
        }}>
            {loading
                ? <CircularProgress color='inherit' />
                : <RaterDataGrid rows={createTable(users)} columns={columns} tableType={TABLE_TYPES.users}/>
            }
        </Box>
    );
}
