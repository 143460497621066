import styled from "styled-components";

export const AboutUsImg = styled("div")({
  flexShrink: 0,
  maxHeight: "500px",
  maxWidth: "500px",
  img: {
    width: "100%",
  },
});

export const AboutUsText = styled("div")({
  fontWeight: 500,
  lineHeight: "30px",
  textAlign: "justify",
});
