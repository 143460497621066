import { useState } from "react"
import { CommentFormContainer, CommentFormMain, CommentFormRating } from "./CommentForm.styles"
import { Box } from "@mui/material"
import { INPUT_IDS, RATING_IDS, ROLES } from "../../constants"
import { texts } from "../../assets/texts"
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../store/store"
import { RaterInput } from "../UI/RaterInput/RaterInput"
import { RaterRating } from "../RaterRating/RaterRating"
import { RaterButton } from "../UI/RaterButton/RaterButton"
import { theme } from "../../assets/theme"
import { displayNotificationModal } from "../../store/slices/UiSlice"
import { createComment } from "../../store/thunks/candidateThunk"
import { clearRating } from "../../store/slices/CandidateSlice"
import { useParams } from "react-router-dom"
import { localStorageGet } from "../../hooks/useLocalStorage"

const noCyrillicRegex: RegExp = /^[^а-яА-ЯёЁ]+$/;

const AddCommentSchema = yup.object({
    company: yup.string().required(texts.add.requiredCompany).matches(noCyrillicRegex, texts.add.noCyrillic),
    comment: yup.string().required(texts.add.requiredComment).matches(noCyrillicRegex, texts.add.noCyrillic),
}).required();

const initialFormValues = {
    company: "",
    comment: "",
}

export const CommentForm: React.FC = () => {
    const [isFormActive, setIsFormActive] = useState(false);
    const { rating } = useSelector((state: RootState) => state.candidates);
    const { candidateId } = useParams();
    const role = localStorageGet("role");

    const dispatch = useDispatch<AppDispatch>();
    const { control, formState: { errors, isValid }, reset } = useForm({
        resolver: yupResolver(AddCommentSchema),
        mode: "onBlur",
    });

    const candidateSkills = RATING_IDS.map((item) => (
        {
            id: item,
            title: texts.add.rating[item as keyof typeof texts.add.rating]
        }
    ))

    const handleOnFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setIsFormActive(true)
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const AddCommentData = {
            company: data.get('company'),
            candidateId: candidateId || "",
            text: data.get('comment'),
            communication: rating.communication,
            coding: rating.coding,
            independence: rating.independence,
            teamwork: rating.teamwork,
            responsibility: rating.responsibility,
            draft: role !== ROLES.admin,
        };

        dispatch(createComment(AddCommentData)).then((res: any) => {
            if (res?.error) {
                dispatch(displayNotificationModal({
                    isOpen: true,
                    message: res?.error?.message,
                    messageType: 'error',
                }));
            } else {
                dispatch(displayNotificationModal({
                    isOpen: true,
                    message: role !== ROLES.admin ? texts.notifications.createAdminCommentSuccess : texts.notifications.createCommentSuccess,
                    messageType: 'success',
                }));
                reset(initialFormValues);
            }
        })
        dispatch(clearRating());
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
        >
            <CommentFormContainer>
                <CommentFormMain>
                    <Controller
                        name="comment"
                        control={control}
                        render={({ field }) =>
                            <RaterInput
                                {...field}
                                type={INPUT_IDS.comment}
                                placeholder={texts.candidate.addComment.comment}
                                errorText={errors?.comment?.message}
                                required
                                small
                                handleOnFocus={handleOnFocus}
                                customStyles={{
                                    backgroundColor: "#ffffff",
                                    minHeight: "40px",
                                }}
                            />
                        }
                    />
                    {isFormActive &&
                        <Box sx={{ marginTop: "15px", display: "flex", alignItems: "center" }}>
                            <Controller
                                name="company"
                                control={control}
                                render={({ field }) =>
                                    <RaterInput
                                        {...field}
                                        type={INPUT_IDS.company}
                                        placeholder={texts.add.company}
                                        errorText={errors?.company?.message}
                                        marginBottomValue="0"
                                        required
                                        small
                                        customStyles={{
                                            backgroundColor: "#ffffff",
                                            marginBottom: "0",
                                        }}
                                    />
                                }
                            />
                            <Box ml={2}>
                                <RaterButton
                                    title={texts.candidate.addComment.create}
                                    disabled={!isValid}
                                    type="submit"
                                    customStyles={{
                                        backgroundColor: theme.bg.main,
                                        padding: "10px",
                                        fontSize: "20px",
                                        lineHeight: "normal",
                                        height: "40px",
                                        textTransform: "none",
                                    }}
                                />
                            </Box>
                        </Box>
                    }
                </CommentFormMain>
                {isFormActive &&
                    <CommentFormRating>
                        <RaterRating rows={candidateSkills} isCommentType />
                    </CommentFormRating>
                }
            </CommentFormContainer>
        </Box>
    )
}