import { createAsyncThunk } from "@reduxjs/toolkit";
import { REQUEST_ROUTES, thunkIds } from "../../constants";
import { api } from "../api";
import {
  CommentProps,
  DeclineCandidateProps,
  DeclineCommentProps,
  NewCandidateProps,
  UpdateCandidateProps,
} from "../../types/candidate.types";
import { rejectWithValue } from "../helpers";
import { NewCommentProps } from "../../components/CommentForm/types";

export const getAllCandidates = createAsyncThunk(
  REQUEST_ROUTES.candidates,
  async () => {
    try {
      const response = await api.get(REQUEST_ROUTES.candidates);
      return response.data;
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.setItem("isLogin", "false");
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const addCandidate = createAsyncThunk(
  thunkIds.addCandidate,
  async (candidate: NewCandidateProps) => {
    try {
      const response = await api.post(REQUEST_ROUTES.candidates, candidate);
      return response.data;
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.setItem("isLogin", "false");
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteCandidate = createAsyncThunk(
  thunkIds.deleteCandidate,
  async (candidateToDecline: DeclineCandidateProps) => {
    try {
      const response = await api.delete(
        `${REQUEST_ROUTES.candidates}/${candidateToDecline.id}`,
        { data: { message: candidateToDecline.message } }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateCandidate = createAsyncThunk(
  thunkIds.updateCandidate,
  async (candidate: UpdateCandidateProps) => {
    try {
      const response = await api.patch(
        `${REQUEST_ROUTES.candidates}/${candidate.id}`,
        { commentId: candidate.commentId }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getCandidateById = createAsyncThunk(
  thunkIds.candidatebyId,
  async (id: string) => {
    try {
      const response = await api.get(`${REQUEST_ROUTES.candidates}/${id}`);
      return response.data;
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.setItem("isLogin", "false");
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createComment = createAsyncThunk(
  thunkIds.createComment,
  async (comment: NewCommentProps) => {
    try {
      const response = await api.post(REQUEST_ROUTES.comments, comment);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateComment = createAsyncThunk(
  thunkIds.approveComment,
  async (comment: CommentProps) => {
    try {
      const response = await api.patch(
        `${REQUEST_ROUTES.comments}/${comment.id}`,
        { ...comment, draft: false }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteComment = createAsyncThunk(
  thunkIds.declineComment,
  async (comment: DeclineCommentProps) => {
    try {
      const response = await api.delete(
        `${REQUEST_ROUTES.comments}/${comment.id}`,
        { data: { message: comment.message } }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const searchCandidateGeneral = createAsyncThunk(
  REQUEST_ROUTES.candidatesSearch,
  async (searchValue: FormDataEntryValue | null) => {
    try {
      const response = await api.post(`${REQUEST_ROUTES.candidatesSearch}?name=${searchValue}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const searchCandidateByLinkedIn = createAsyncThunk(
  REQUEST_ROUTES.candidatesSearchLinkedIn,
  async (searchValue: string) => {
    try {
      const response = await api.post(REQUEST_ROUTES.candidatesSearchLinkedIn, {linkedinUrl: searchValue});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const searchCandidateByGithub = createAsyncThunk(
  REQUEST_ROUTES.candidatesSearchGithub,
  async (searchValue: string) => {
    try {
      const response = await api.post(REQUEST_ROUTES.candidatesSearchGithub, {githubUrl: searchValue});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
