import {
    CandidateContainer,
    CandidateInfo,
    CandidateInfoItem,
    CandidateInfoItemLink,
    CandidateInfoItemLinkContainer,
    CandidateInfoItemTitle,
    CandidateInfoLinks,
    CandidateInfoParam,
    CandidateMain,
    CandidateRating
} from "./Candidate.styles"
import { ICandidate } from "../../types/candidate.types";
import { texts } from "../../assets/texts";
import { CommentsList } from "../CommentsList/CommentsList";
import { calculateMiddleRatingValues, calculateTotalRatingFromCommentsList } from "../../helpers/calculateRating";
import { RaterRating } from "../RaterRating/RaterRating";
import { RATING_IDS, ROLES } from "../../constants";
import { CommentForm } from "../CommentForm/CommentForm";
import { localStorageGet } from "../../hooks/useLocalStorage";
import { Box } from "@mui/material";

export const Candidate: React.FC<{ candidate: ICandidate, isCandidateNew?: boolean }> = ({ candidate, isCandidateNew }) => {
    const { name, position, linkedinUrl, facebookUrl, githubUrl, comments } = candidate;
    const role = localStorageGet("role");
    const moderatedComments = comments.filter((comment) => !comment.draft);

    const middleRatingValues = calculateMiddleRatingValues(moderatedComments);
    const totalRating = calculateTotalRatingFromCommentsList(moderatedComments);
    const ratingWithTotal = { ...middleRatingValues, total: totalRating }
    const commentsAmount = moderatedComments?.length;

    const candidateSkills = RATING_IDS.map((item) => (
        {
            id: item,
            title: texts.add.rating[item as keyof typeof texts.add.rating]
        }
    ))

    const candidateSkillsWithTotal =
        [
            {
                id: "total",
                title: texts.candidate.rating.total,
            },
            ...candidateSkills
        ]

    return (
        <Box sx={{
            marginRight: (isCandidateNew) ? 0 : "360px",
            flex: 1,
            "@media screen and (max-width: 992px)": {
                marginRight: 0,
                flex: "0 auto",
            },
        }}>
            <CandidateContainer isCandidateNew={isCandidateNew}>
                <CandidateMain>
                    <CandidateInfo>
                        <CandidateInfoItem>
                            <CandidateInfoParam>
                                <CandidateInfoItemTitle>{texts.candidate.name}</CandidateInfoItemTitle>
                                {name}
                            </CandidateInfoParam>
                            <CandidateInfoParam>
                                <CandidateInfoItemTitle>{texts.candidate.position}</CandidateInfoItemTitle>
                                {position}
                            </CandidateInfoParam>
                        </CandidateInfoItem>
                        <CandidateInfoLinks>
                            <CandidateInfoParam>
                                <CandidateInfoItemTitle>{texts.candidate.linkedinUrl}</CandidateInfoItemTitle>
                                <CandidateInfoItemLinkContainer>
                                    <CandidateInfoItemLink href={linkedinUrl}>{linkedinUrl}</CandidateInfoItemLink>
                                </CandidateInfoItemLinkContainer>
                            </CandidateInfoParam>
                            <CandidateInfoParam>
                                <CandidateInfoItemTitle>{texts.candidate.facebookUrl}</CandidateInfoItemTitle>
                                <CandidateInfoItemLinkContainer>
                                    <CandidateInfoItemLink href={facebookUrl}>{facebookUrl}</CandidateInfoItemLink>
                                </CandidateInfoItemLinkContainer>
                            </CandidateInfoParam>
                            <CandidateInfoParam>
                                <CandidateInfoItemTitle>{texts.candidate.githubUrl}</CandidateInfoItemTitle>
                                <CandidateInfoItemLinkContainer>
                                    <CandidateInfoItemLink href={githubUrl}>{githubUrl}</CandidateInfoItemLink>
                                </CandidateInfoItemLinkContainer>
                            </CandidateInfoParam>
                        </CandidateInfoLinks>
                    </CandidateInfo>
                    <CommentForm />
                    <CommentsList candidateById={candidate} />
                </CandidateMain>
                {!isCandidateNew &&
                    <CandidateRating>
                        <RaterRating rows={candidateSkillsWithTotal} ratingParams={ratingWithTotal} readOnly commentsAmount={commentsAmount} />
                    </CandidateRating>
                }
            </CandidateContainer>
        </Box>
    )
}

