import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { localStorageGet } from "../../hooks/useLocalStorage";
import { loginUser } from "../thunks/loginThunk";
import { IUser, IUserLoginResponse } from "../../types/user.types";
const isLogin = localStorageGet("isLogin");

export interface IStore {
  user: IUser | null;
  role: string | null;
  isLogin: boolean | any;
  loading: boolean | any;
}

const initialState: IStore = {
  user: null,
  role: "",
  loading: false,
  isLogin: isLogin ? isLogin : false,
};

const UserSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [loginUser.fulfilled.type]: (
      state: IStore,
      action: PayloadAction<IUserLoginResponse>
    ) => {
      state.loading = false;
      state.user = action.payload;
      state.role = action.payload?.role;
      state.isLogin = true;
    },
  },
});
export const { setLoader } = UserSlice.actions;

export default UserSlice.reducer;
