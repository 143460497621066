import styled from "styled-components";
import { theme } from "../../assets/theme";

export const CommentFormContainer = styled("div")({
  backgroundColor: theme.bg.second,
  borderRadius: "15px",
  display: "flex",
});

export const CommentFormMain = styled("div")({
  padding: "15px",
  flex: 1,
});

export const CommentFormRating = styled("div")({
  borderLeft: "1px solid #000000",
});
