import { texts } from "../../assets/texts";
import { CommentsList } from "../CommentsList/CommentsList";
import { calculateCompanyMiddleRatingValues, calculateCompanyTotalRatingFromCommentsList } from "../../helpers/calculateRating";
import { RaterRating } from "../RaterRating/RaterRating";
import { COMPANY_RATING_IDS } from "../../constants";
import { localStorageGet } from "../../hooks/useLocalStorage";
import { Box } from "@mui/material";
import { CompanyProps } from "../../types/companies.types";
import { CompanyContainer, CompanyInfo, CompanyInfoItem, CompanyInfoItemLink, CompanyInfoItemLinkContainer, CompanyInfoItemTitle, CompanyInfoLinks, CompanyInfoParam, CompanyMain, CompanyRating } from "./Company.styles";
import { CompanyCommentForm } from "../CompanyCommentForm/CompanyCommentForm";

export const Company: React.FC<{ company: CompanyProps, isCompanyNew?: boolean }> = ({ company, isCompanyNew }) => {
    const { companyName, douUrl, linkedinUrl, djinniUrl, siteUrl, comments } = company;
    const role = localStorageGet("role");
    const moderatedComments = comments?.filter((comment) => !comment.draft) || [];

    const middleRatingValues = calculateCompanyMiddleRatingValues(moderatedComments);
    const totalRating = calculateCompanyTotalRatingFromCommentsList(moderatedComments);
    const ratingWithTotal = { ...middleRatingValues, total: totalRating }
    const commentsAmount = moderatedComments?.length;

    const companyRatingParams = COMPANY_RATING_IDS.map((item) => (
        {
            id: item,
            title: texts.addCompany.rating[item as keyof typeof texts.addCompany.rating]
        }
    ))

    const companyRatingParamsWithTotal =
        [
            {
                id: "total",
                title: texts.companies.rating.total,
            },
            ...companyRatingParams
        ]

    return (
        <Box sx={{
            marginRight: (isCompanyNew) ? 0 : "360px",
            flex: 1,
            "@media screen and (max-width: 992px)": {
                marginRight: 0,
                flex: "0 auto",
            },
        }}>
            <CompanyContainer isCompanyNew={isCompanyNew}>
                <CompanyMain>
                    <CompanyInfo>
                        <CompanyInfoItem>
                            <CompanyInfoParam>
                                <CompanyInfoItemTitle>{texts.companies.name}</CompanyInfoItemTitle>
                                <Box sx={{ textOverflow: "ellipsis" }}>
                                    {companyName}
                                </Box>
                            </CompanyInfoParam>
                            <CompanyInfoParam>
                                <CompanyInfoItemTitle>{texts.companies.website}</CompanyInfoItemTitle>
                                <CompanyInfoItemLinkContainer>
                                    <CompanyInfoItemLink href={siteUrl}>{siteUrl}</CompanyInfoItemLink>
                                </CompanyInfoItemLinkContainer>
                            </CompanyInfoParam>
                        </CompanyInfoItem>
                        <CompanyInfoLinks>
                            <CompanyInfoParam>
                                <CompanyInfoItemTitle>{texts.companies.douUrl}</CompanyInfoItemTitle>
                                <CompanyInfoItemLinkContainer>
                                    <CompanyInfoItemLink href={douUrl}>{douUrl}</CompanyInfoItemLink>
                                </CompanyInfoItemLinkContainer>
                            </CompanyInfoParam>
                            <CompanyInfoParam>
                                <CompanyInfoItemTitle>{texts.companies.linkedinUrl}</CompanyInfoItemTitle>
                                <CompanyInfoItemLinkContainer>
                                    <CompanyInfoItemLink href={linkedinUrl}>{linkedinUrl}</CompanyInfoItemLink>
                                </CompanyInfoItemLinkContainer>
                            </CompanyInfoParam>
                            <CompanyInfoParam>
                                <CompanyInfoItemTitle>{texts.companies.djinniUrl}</CompanyInfoItemTitle>
                                <CompanyInfoItemLinkContainer>
                                    <CompanyInfoItemLink href={djinniUrl}>{djinniUrl}</CompanyInfoItemLink>
                                </CompanyInfoItemLinkContainer>
                            </CompanyInfoParam>
                        </CompanyInfoLinks>
                    </CompanyInfo>
                    <CompanyCommentForm />
                    <CommentsList companyById={company} />
                </CompanyMain>
                {!isCompanyNew &&
                    <CompanyRating>
                        <RaterRating rows={companyRatingParamsWithTotal} ratingParams={ratingWithTotal} readOnly commentsAmount={commentsAmount} />
                    </CompanyRating>
                }
            </CompanyContainer>
        </Box>
    )
}

