import { createAsyncThunk } from "@reduxjs/toolkit";
import { REQUEST_ROUTES, thunkIds } from "../../constants";
import { rejectWithValue } from "../helpers";
import { api } from "../api";
import { CompanyCommentProps, DeleteCompanyProps, NewCompanyProps } from "../../types/companies.types";
import { NewCompanyCommentProps } from "../../components/CompanyCommentForm/types";
import { DeclineCommentProps } from "../../types/candidate.types";

export const getAllCompanies = createAsyncThunk(
  REQUEST_ROUTES.companies,
  async () => {
    try {
      const response = await api.get(REQUEST_ROUTES.companies);
      return response.data;
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.setItem("isLogin", "false");
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const addCompany = createAsyncThunk(
  thunkIds.addCompany,
  async (company: NewCompanyProps) => {
    try {
      const response = await api.post(REQUEST_ROUTES.companies, company);
      return response.data;
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.setItem("isLogin", "false");
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteCompany = createAsyncThunk(
  thunkIds.deleteCompany,
  async (companyToDelete: DeleteCompanyProps) => {
    try {
      const response = await api.delete(
        `${REQUEST_ROUTES.companies}/${companyToDelete.id}`,
        { data: { message: companyToDelete.message } }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getCompanyById = createAsyncThunk(
  thunkIds.companyById,
  async (id: string) => {
    try {
      const response = await api.get(`${REQUEST_ROUTES.companies}/${id}`);
      return response.data;
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.setItem("isLogin", "false");
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createCompanyComment = createAsyncThunk(
  thunkIds.createCompanyComment,
  async (comment: NewCompanyCommentProps) => {
    try {
      const response = await api.post(REQUEST_ROUTES.companyComments, comment);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateCompanyComment = createAsyncThunk(
  thunkIds.approveCompanyComment,
  async (comment: CompanyCommentProps) => {
    try {
      const response = await api.patch(
        `${REQUEST_ROUTES.companyComments}/${comment.id}`,
        { ...comment, draft: false }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteCompanyComment = createAsyncThunk(
  thunkIds.declineCompanyComment,
  async (comment: DeclineCommentProps) => {
    try {
      const response = await api.delete(
        `${REQUEST_ROUTES.companyComments}/${comment.id}`,
        { data: { message: comment.message } }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const searchCompaniesGeneral = createAsyncThunk(
  REQUEST_ROUTES.companiesSearch,
  async (searchValue: FormDataEntryValue | null) => {
    try {
      const response = await api.post(`${REQUEST_ROUTES.companiesSearch}?searchProp=${searchValue}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const searchCompanyByName = createAsyncThunk(
  REQUEST_ROUTES.companiesSearchName,
  async (searchValue: string) => {
    try {
      const response = await api.post(REQUEST_ROUTES.companiesSearchName, {companyName: searchValue});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const searchCompanyBySite = createAsyncThunk(
  REQUEST_ROUTES.companiesSearchSite,
  async (searchValue: string) => {
    try {
      const response = await api.post(REQUEST_ROUTES.companiesSearchSite, {siteUrl: searchValue});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const searchCompanyByLinkedIn = createAsyncThunk(
  REQUEST_ROUTES.companiesSearchLinkedIn,
  async (searchValue: string) => {
    try {
      const response = await api.post(REQUEST_ROUTES.companiesSearchLinkedIn, {linkedinUrl: searchValue});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
