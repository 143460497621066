import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CompanyProps,
  CompanyRatingParamProps,
  CompanyStore,
} from "../../types/companies.types";
import { getAllCompanies, getCompanyById, searchCompaniesGeneral } from "../thunks/companiesThunk";

export const initialCompanyRating = {
  communication: 0,
  timeManagement: 0,
  flexibility: 0,
  expertise: 0,
  quality: 0,
};

export const initialCompany = {
  id: "",
  companyName: "",
  siteUrl: "",
  linkedinUrl: "",
  douUrl: "",
  djinniUrl: "",
  rating: "",
  comments: [],
  createdAt: "",
}

const initialState: CompanyStore = {
  companies: [],
  loading: false,
  rating: initialCompanyRating,
  stages: [],
  companyById: initialCompany,
};

const initialStage = {
  id: "",
  stageName: "",
  shortDescr: "",
  description: "",
  days: 0,
  hours: 0,
};

const CompaniesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    setCompanyRating: (
      state: CompanyStore,
      action: PayloadAction<CompanyRatingParamProps>
    ) => {
      const { id, value } = action.payload;
      state.rating = { ...state.rating, [id]: value };
    },
    clearCompanyRating: (state: CompanyStore) => {
      state.rating = initialCompanyRating;
      state.loading = false;
    },
    createNewStage: (state: CompanyStore) => {
      state.stages = [
        ...state.stages,
        { ...initialStage, id: `stage-${state.stages.length + 1}` },
      ];
    },
    clearStages: (state: CompanyStore) => {
      state.stages = [];
    },
    cancelLastStage: (state: CompanyStore) => {
      state.stages.pop();
    },
  },
  extraReducers: {
    [getAllCompanies.pending.type]: (
      state: CompanyStore,
      action: PayloadAction<CompanyProps[]>
    ) => {
      state.loading = true;
      state.companies = action.payload;
    },
    [getAllCompanies.fulfilled.type]: (
      state: CompanyStore,
      action: PayloadAction<CompanyProps[]>
    ) => {
      state.loading = false;
      state.companies = action.payload;
    },
    [getCompanyById.pending.type]: (
      state: CompanyStore,
      action: PayloadAction<CompanyProps>
    ) => {
      state.loading = true;
      state.companyById = action.payload;
    },
    [getCompanyById.fulfilled.type]: (
      state: CompanyStore,
      action: PayloadAction<CompanyProps>
    ) => {
      state.loading = false;
      state.companyById = action.payload;
    },
    [searchCompaniesGeneral.pending.type]: (
      state: CompanyStore,
      action: PayloadAction<CompanyProps[]>
    ) => {
      state.loading = true;
      state.companies = action.payload;
    },
    [searchCompaniesGeneral.fulfilled.type]: (
      state: CompanyStore,
      action: PayloadAction<CompanyProps[]>
    ) => {
      state.loading = false;
      state.companies = action.payload;
    },
  },
});

export const {
  setCompanyRating,
  clearCompanyRating,
  createNewStage,
  clearStages,
  cancelLastStage,
} = CompaniesSlice.actions;

export default CompaniesSlice.reducer;
