import { Navigate, Outlet, Route, RouterProvider, createBrowserRouter, createRoutesFromElements, useLocation } from "react-router-dom";
import { About } from "../pages/About";
import { Layout } from "./Layout/Layout";
import { SignUp } from "../pages/SignUp";
import { SignIn } from "../pages/SignIn";
import { AdminList } from "../pages/AdminList";
import { AdminUsers } from "../pages/AdminUsers";
import { ROUTES } from "../constants";
import { CandidatePage } from "../pages/CandidatePage";
import { localStorageGet } from "../hooks/useLocalStorage";
import { Moderation } from "../pages/Moderation";
import { CompanyPage } from "../pages/CompanyPage";
import { AddCompanyPage } from "../pages/AddCompanyPage";
import { AdminCompanies } from "../pages/AdminCompanies";
import { CandidatesPage } from "../pages/CandidatesPage";
import { CompaniesPage } from "../pages/CompaniesPage";
import { AddCandidatePage } from "../pages/AddCandidatePage";
import { ResetPassEmailPage } from "../pages/ResetPassEmailPage";
import { ResetPasswordPage } from "../pages/ResetPasswordPage";

export const PrivateRoute: React.FC = () => {
    const isLogin = localStorageGet("isLogin");
    const location = useLocation();

    if (!isLogin && location.pathname !== ROUTES.resetPassEmail && location.pathname !== ROUTES.resetPassword) {
        return <Navigate to={ROUTES.signIn} />
    } else {
        return <Outlet />;
    }
}

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<Layout />}>
            <Route element={<PrivateRoute />}>
                <Route path={ROUTES.candidates} element={<CandidatesPage />} />
                <Route path={ROUTES.adminCandidates} element={<AdminList />} />
                <Route path={ROUTES.users} element={<AdminUsers />} />
                <Route path={ROUTES.candidate}>
                    <Route path=":candidateId" element={<CandidatePage />} />
                </Route>
                <Route path={ROUTES.company}>
                    <Route path=":companyId" element={<CompanyPage />} />
                </Route>
                <Route path={ROUTES.home} element={<About />} />
                <Route path={ROUTES.moderation} element={<Moderation />} />
                <Route path={ROUTES.companies} element={<CompaniesPage />} />
                <Route path={ROUTES.adminCompanies} element={<AdminCompanies />} />
                <Route path={ROUTES.addCompany} element={<AddCompanyPage />} />
                <Route path={ROUTES.addCandidate} element={<AddCandidatePage />} />
                <Route path={ROUTES.resetPassEmail} element={<ResetPassEmailPage />} />
                <Route path={ROUTES.resetPassword} element={<ResetPasswordPage />} />
            </Route>
            <Route path={ROUTES.signUp} element={<SignUp />} />
            <Route path={ROUTES.signIn} element={<SignIn />} />
            {/* <Route path={ROUTES.notFound} element={<div>Not Found!</div>} /> */}
        </Route>
    )
);

export const AppRouter: React.FC = () => {
    return (
        <RouterProvider router={router} />
    )
}
