import { AddCandidateContainer, Wrapper } from "./AddCandidate.styles"
import { AddCandidateForm } from "./AddCandidateForm.tsx/AddCandidateForm"

export const AddCandidate: React.FC = () => {
    return (
        <Wrapper>
            <AddCandidateContainer>
                <AddCandidateForm />
            </AddCandidateContainer>
        </Wrapper>
    )
}