import Button from "@mui/material/Button"
import { theme } from "../../../assets/theme"
import { RaterButtonProps } from "./types"
import { BTN_ICON_TYPES } from "../../../constants"
import AddIcon from '@mui/icons-material/Add';

export const RaterButton: React.FC<RaterButtonProps> = ({ title, disabled, clickHandler, size, customStyles, type, fullWidth, startIconType }) => {

    const getStartIcon = (startIconType: string) => {
        switch (startIconType) {
            case BTN_ICON_TYPES.add:
                return <AddIcon />
        }
    }

    return (
        <Button
            type={type ? type : "button"}
            variant="contained"
            disabled={disabled}
            onClick={clickHandler}
            fullWidth={fullWidth}
            startIcon={startIconType ? getStartIcon(startIconType) : null}
            sx={{
                padding: size === "small" ? "0 20px" : "0 40px",
                width: fullWidth ? "100%" : "fit-content",
                backgroundColor: theme.bg.second,
                borderRadius: "30px",
                fontSize: size === "small" ? "20px" : "32px",
                fontFamily: "'Inter', sans-serif",
                fontWeight: 700,
                color: theme.font.color.main,
                display: "flex",
                boxShadow: "none",
                lineHeight: size === "small" ? "40px" : "60px",
                "& .MuiButton-startIcon>*:nth-of-type(1)": {
                    fontSize: theme.font.size.large
                },
                ...customStyles,
                "&:hover": {
                    backgroundColor: theme.bg.active,
                    boxShadow: "none",
                    color: theme.font.color.light,
                }
            }}
        >
            {title}
        </Button>
    )
}

