import Box from "@mui/material/Box";
import { texts } from "../../assets/texts";
import { InviteUserFormTitle } from "./InviteUserForm.styles";
import { INPUT_IDS, ROUTES } from "../../constants";
import { RaterInput } from "../UI/RaterInput/RaterInput";
import arrowInCircle from "../../assets/icons/arrowInCircle.svg"
import { theme } from "../../assets/theme";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { inviteThunk } from "../../store/thunks/inviteThunk";
import { displayNotificationModal } from "../../store/slices/UiSlice";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const InviteUserSchema = yup.object({
    email: yup.string().required(texts.add.requiredEmail).email(texts.add.invalidEmail),
}).required();


export const InviteUserForm: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { control, formState: { errors, isValid }, reset } = useForm({
        resolver: yupResolver(InviteUserSchema),
        mode: "onBlur",
    });

    const initialInviteForm = {
        email: "",
    }

    const handleInvite = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const inviteData = {
            email: data.get('email'),
            link: `${process.env.REACT_APP_BASE_URL}${ROUTES.signUp}`,
        }
        await dispatch(inviteThunk({
            email: inviteData.email,
            link: inviteData.link || "",
        }))
            .then((res: any) => {
                if (res?.error) {
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: res?.error?.message,
                        messageType: 'error',
                    }));
                } else {
                    reset(initialInviteForm);
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: "Invite was sent",
                        messageType: 'success',
                    }));
                }
            });
    };

    return (
        <Box sx={{ paddingTop: "6vh", paddingBottom: "6vh" }}>
            <InviteUserFormTitle>{texts.admin.invite}</InviteUserFormTitle>
            <Box component="form"
                onSubmit={handleInvite}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    height: "100%",
                }}
            >
                <Box sx={{ position: "relative" }}>
                    <Controller
                        name="email"
                        control={control}
                        render={({ field }) =>
                            <RaterInput
                                {...field}
                                type={INPUT_IDS.email}
                                placeholder={texts.sign.email}
                                errorText={errors?.email?.message}
                                required
                            />
                        }
                    />
                    <Button
                        variant="text"
                        type="submit"
                        startIcon={<img src={arrowInCircle} alt="arrow in circle" />}
                        disabled={!isValid}
                        sx={{
                            position: "absolute" as const,
                            right: 0,
                            top: "50%",
                            transform: "translateY(-50%)",
                            borderLeft: "1px solid #000000",
                            borderRadius: "0 15px 15px 0",
                            height: "100%",
                            transition: "background 0.3s linear",
                            opacity: !isValid ? 0.3 : 1,
                            ".MuiButton-startIcon": {
                                margin: 0,
                            },
                            ":hover": {
                                backgroundColor: theme.bg.activeLight,
                                borderRadius: "0 15px 15px 0",
                                transition: "background 0.3s linear",
                            }
                        }}
                    />
                </Box>
            </Box>
        </Box>
    )
}