import { Box } from "@mui/material"
import { RaterButton } from "../components/UI/RaterButton/RaterButton"
import { RaterInput } from "../components/UI/RaterInput/RaterInput"
import { INPUT_IDS, ROUTES } from "../constants"
import { Controller, useForm } from "react-hook-form"
import { texts } from "../assets/texts"
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { theme } from "../assets/theme"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../store/store"
import { displayNotificationModal } from "../store/slices/UiSlice"
import { useLocation, useNavigate } from "react-router-dom"
import { changePassword } from "../store/thunks/loginThunk"

const ResetPasswordSchema = yup.object({
    password: yup.string()
        .required(texts.add.requiredPassword)
        .min(6, texts.add.invalidPasswordMin)
        .matches(/[0-9]/, texts.add.invalidPasswordNumber)
        .matches(/[a-z]/, texts.add.invalidPasswordLowercase)
        .matches(/[A-Z]/, texts.add.invalidPasswordUppercase),
    confirmPassword: yup.string()
        .oneOf([yup.ref('password'), undefined], texts.sign.passwordsMustMatches)
}).required();

export const ResetPasswordPage: React.FC = () => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const token = searchParams.get("token");
    const emailToResetPass = localStorage.getItem("emailToResetPass")

    const { control, formState: { errors, isValid } } = useForm({
        resolver: yupResolver(ResetPasswordSchema),
        mode: "onBlur",
    });
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const changePasswordData = {
            newPassword: data.get('password'),
        }

        if (token) {
            dispatch(changePassword({
                email: emailToResetPass,
                newPassword: changePasswordData.newPassword || "",
                token: token || "",
            }))
                .then((res: any) => {
                    if (res?.error) {
                        dispatch(displayNotificationModal({
                            isOpen: true,
                            message: res?.error?.message,
                            messageType: 'error',
                        }));
                    } else {
                        dispatch(displayNotificationModal({
                            isOpen: true,
                            message: texts.notifications.successResetPassword,
                            messageType: 'success',
                        }));
                        navigate(ROUTES.signIn);
                    }
                });
        }
    };

    return (
        <Box sx={{
            height: "calc(100vh - 60px - 92px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <Box component="form"
                onSubmit={handleSubmit}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "10px",
                    height: "350px",
                    backgroundColor: theme.bg.signLight,
                    borderRadius: "60px",
                    padding: "30px",
                }}
            >
                <Box sx={{
                    textAlign: "center",
                    fontSize: theme.font.size.medium,
                    marginBottom: "20px",
                }}>
                    {texts.forgotPassword.emailFormTitle}
                </Box>
                <Controller
                    name="password"
                    control={control}
                    render={({ field }) =>
                        <RaterInput
                            {...field}
                            type={INPUT_IDS.password}
                            placeholder={texts.sign.password}
                            errorText={errors?.password?.message}
                            marginBottomValue="30px"
                            required
                        />
                    }
                />
                <Controller
                    name="confirmPassword"
                    control={control}
                    render={({ field }) =>
                        <RaterInput
                            {...field}
                            type={INPUT_IDS.password}
                            placeholder={texts.sign.confirmPassword}
                            errorText={errors?.confirmPassword?.message}
                            marginBottomValue="30px"
                            required
                        />
                    }
                />
                <Box sx={{ margin: "0 auto" }}>
                    <RaterButton
                        title={texts.forgotPassword.resetBtn}
                        disabled={!isValid}
                        type="submit"
                    />
                </Box>
            </Box>
        </Box>
    )
}