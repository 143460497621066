import { Outlet } from "react-router-dom"
import { Header } from "../Header/Header"
import { Footer } from "../Footer/Footer"
import { Main } from "./Layout.styles"
import Box from "@mui/material/Box"
import { theme } from "../../assets/theme"
import { createContext, useState } from "react"
import { Notifications } from "../Notifications/Notifications"
import { LayoutContextProps } from "./types"

const initialContext = {
    open: false,
    handleOpen: () => { },
    handleClose: () => { },
    idToDelete: "",
    setIdToDelete: () => { },
}

export const LayoutContext = createContext<LayoutContextProps>(initialContext);

export const Layout: React.FC = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [idToDelete, setIdToDelete] = useState("");

    return (
        <LayoutContext.Provider value={{
            open,
            handleOpen,
            handleClose,
            idToDelete,
            setIdToDelete,
        }}>
            <Box sx={{
                boxShadow: "0px 9px 8px -9px rgba(69,69,69,0.74)", 
                position: "fixed",
                backgroundColor: theme.bg.second,
                width: "100%",
                zIndex: 100,
            }}>
                <Header />
            </Box>
            <Box sx={{ backgroundColor: theme.bg.main, paddingTop: "60px" }}>
                <Main>
                    <Outlet />
                </Main>
            </Box>
            <Box sx={{ backgroundColor: theme.bg.main, }}>
                <Footer />
            </Box>
            <Notifications />
        </LayoutContext.Provider>
    )
}