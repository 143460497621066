import { HeaderBg, HeaderContainer, HeaderLogo, HeaderLogoIcon, HeaderLogoTitle, LogoutIcon, Tools, ToolsLinks, ToolsLogin, UserName } from "./Header.styles"
import logoShort from "../../assets/images/logoShort.png"
import { texts } from "../../assets/texts";
import { useLocation, useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import { theme } from "../../assets/theme";
import logout from "../../assets/icons/logout.svg";
import { ROLES, ROUTES } from "../../constants";
import { logoutUser } from "../../store/thunks/loginThunk";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { localStorageGet } from "../../hooks/useLocalStorage";
import { displayNotificationModal } from "../../store/slices/UiSlice";

export const Header: React.FC = () => {
    const { rater } = texts.header;
    const dispatch = useDispatch<AppDispatch>();
    const id = localStorageGet("id");
    const isLogin = localStorageGet("isLogin");
    const userName = localStorageGet("name");
    const role = localStorageGet("role");
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useSelector((state: RootState) => state.login);

    const handleLogout = async () => {
        await dispatch(logoutUser(id))
            .then((res: any) => {
                if (res?.error) {
                    if (res?.error.message === texts.notifications.userUnauthorized) {
                        dispatch(displayNotificationModal({
                            isOpen: true,
                            message: texts.notifications.refreshUnauthorized,
                            messageType: 'warning',
                        }));
                        navigate(ROUTES.signIn)
                    }
                } else {
                    navigate(ROUTES.signIn);
                }
            });
    }

    return (
        <HeaderBg>
            <HeaderContainer>
                <Link component={RouterLink} to={role === ROLES.admin ? ROUTES.moderation : ROUTES.home}
                    sx={{
                        fontWeight: 700,
                        textDecorationColor: "transparent",
                        transition: "all 0.3s linear",
                        "&:hover": {
                            cursor: "pointer",
                            textDecorationColor: "transparent",
                        }
                    }}>
                    <HeaderLogo>
                        <HeaderLogoIcon>
                            <img src={logoShort} alt="letter D with face shape" />
                        </HeaderLogoIcon>
                        <HeaderLogoTitle>
                            {rater}
                        </HeaderLogoTitle>
                    </HeaderLogo>
                </Link>
                {isLogin &&
                    <Tools>
                        <ToolsLinks>
                            {role === ROLES.admin &&
                                <>
                                    <Link component={RouterLink} to={ROUTES.moderation}
                                        sx={{
                                            color: location.pathname.includes(ROUTES.moderation) ? theme.font.color.active : theme.font.color.main,
                                            fontWeight: 700,
                                            textDecorationColor: location.pathname.includes(ROUTES.moderation) ? "transparent" : theme.font.color.main,
                                            transition: "all 0.3s linear",
                                            "&:hover": {
                                                color: theme.font.color.active,
                                                textDecorationColor: "transparent",
                                            }
                                        }}>
                                        {texts.navigation.moderation}
                                    </Link>
                                    <Link component={RouterLink} to={ROUTES.users}
                                        sx={{
                                            color: location.pathname.includes(ROUTES.users) ? theme.font.color.active : theme.font.color.main,
                                            fontWeight: 700,
                                            textDecorationColor: location.pathname.includes(ROUTES.users) ? "transparent" : theme.font.color.main,
                                            transition: "all 0.3s linear",
                                            "&:hover": {
                                                color: theme.font.color.active,
                                                textDecorationColor: "transparent",
                                            }
                                        }}>
                                        {texts.header.users}
                                    </Link></>
                            }
                            <Link component={RouterLink} to={role === ROLES.admin ? ROUTES.adminCandidates : ROUTES.candidates}
                                sx={{
                                    color: location.pathname.includes(role === ROLES.admin ? ROUTES.adminCandidates : ROUTES.candidates) ? theme.font.color.active : theme.font.color.main,
                                    fontWeight: 700,
                                    textDecorationColor: location.pathname.includes(role === ROLES.admin ? ROUTES.adminCandidates : ROUTES.candidates) ? "transparent" : theme.font.color.main,
                                    transition: "all 0.3s linear",
                                    "&:hover": {
                                        color: theme.font.color.active,
                                        textDecorationColor: "transparent",
                                    }
                                }}>
                                {texts.navigation.candidates}
                            </Link>
                            <Link component={RouterLink} to={role === ROLES.admin ? ROUTES.adminCompanies : ROUTES.companies}
                                sx={{
                                    color: location.pathname.includes(role === ROLES.admin ? ROUTES.adminCompanies : ROUTES.companies) ? theme.font.color.active : theme.font.color.main,
                                    fontWeight: 700,
                                    textDecorationColor: location.pathname.includes(role === ROLES.admin ? ROUTES.adminCompanies : ROUTES.companies) ? "transparent" : theme.font.color.main,
                                    transition: "all 0.3s linear",
                                    "&:hover": {
                                        color: theme.font.color.active,
                                        textDecorationColor: "transparent",
                                    }
                                }}>
                                {texts.navigation.companies}
                            </Link>
                            {role !== ROLES.admin &&
                                <Link component={RouterLink} to={ROUTES.home}
                                    sx={{
                                        color: location.pathname === ROUTES.home ? theme.font.color.active : theme.font.color.main,
                                        fontWeight: 700,
                                        textDecorationColor: location.pathname.includes(ROUTES.home) ? "transparent" : theme.font.color.main,
                                        transition: "all 0.3s linear",
                                        "&:hover": {
                                            color: theme.font.color.active,
                                            textDecorationColor: "transparent",
                                        }
                                    }}>
                                    {texts.navigation.aboutUs}
                                </Link>
                            }
                        </ToolsLinks>
                        <ToolsLogin>
                            <UserName>{user ? user?.name : userName}</UserName>
                            <LogoutIcon onClick={handleLogout}>
                                <img src={logout} alt="screen and right arrow" />
                            </LogoutIcon>
                        </ToolsLogin>
                    </Tools>
                }
            </HeaderContainer>
        </HeaderBg >
    )
}