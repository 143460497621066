import Box from "@mui/material/Box";
import { texts } from "../../../assets/texts";
import { INPUT_IDS, RATING_IDS, ROUTES } from "../../../constants";
import { RaterInput } from "../../UI/RaterInput/RaterInput";
import { RaterButton } from "../../UI/RaterButton/RaterButton";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { addCandidate, searchCandidateByGithub, searchCandidateByLinkedIn } from "../../../store/thunks/candidateThunk";
import { displayNotificationModal } from "../../../store/slices/UiSlice";
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { RaterRating } from "../../RaterRating/RaterRating";
import { clearRating } from "../../../store/slices/CandidateSlice";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { theme } from "../../../assets/theme";
import { useContext, useState } from "react";
import { LayoutContext } from "../../Layout/Layout";
import { useNavigate } from "react-router-dom";

const noCyrillicRegex: RegExp = /^[^а-яА-ЯёЁ]+$/;

const initialFormValues = {
    candidateName: "",
    position: "",
    company: "",
    urlLinkedIn: "",
    urlFacebook: "",
    urlGitHub: "",
    comment: "",
}

export const AddCandidateForm: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { open, handleClose, handleOpen } = useContext(LayoutContext);
    const [existId, setExistId] = useState('');
    const [linkedInValue, setLinkedInValue] = useState("");
    const [githubValue, setGithubValue] = useState("");

    const AddCandidateSchema = yup.object({
        candidateName: yup.string().required(texts.add.requiredName).matches(noCyrillicRegex, texts.add.noCyrillic),
        position: yup.string().required(texts.add.requiredPosition).matches(noCyrillicRegex, texts.add.noCyrillic),
        company: yup.string().required(texts.add.requiredCompany).matches(noCyrillicRegex, texts.add.noCyrillic),
        urlLinkedIn: yup.string()
            .required(texts.add.requiredLinkedIn)
            .matches(/^https:\/\/www\.linkedin\.com\/+\S{1,}/, texts.add.notContainLinkedIn)
            .url(texts.add.invalidUrl)
            .test('candidate-exist-linkedIn', texts.add.candidateExists, function (value, context) {
                if (value && value.length) {
                    setLinkedInValue(value);
                    if (value !== linkedInValue) {
                        setTimeout(() => {
                            dispatch(searchCandidateByLinkedIn(value)).then((res: any) => {
                                if (res?.error) {
                                    return context.createError({ message: res?.error?.message });
                                } else {
                                    if (res.payload.exist) {
                                        setExistId(res.payload.id || "")
                                        if (errors && ((errors.urlLinkedIn && errors.urlLinkedIn.type === 'candidate-exist-linkedIn') || !errors.urlLinkedIn)) {
                                            setTimeout(() => {
                                                handleOpen();
                                            }, 200)
                                            return false
                                        }
                                    } else {
                                        setExistId("");
                                        return true
                                    }
                                }
                            })
                        }, 700)
                    }
                }
                return !(existId && existId.length);
            }),
        urlFacebook: yup.string().url(texts.add.invalidUrl),
        urlGitHub: yup.string()
            .test('isGithubLink', texts.add.notContainGithub, (value) => {
                if (value && value.length) {
                    return (/^https:\/\/github\.com\/+\S{1,}/.test(value))
                } else return true
            })
            .url(texts.add.invalidUrl)
            .test('candidate-exist-github', texts.add.candidateExists, function (value, context) {
                if (value && value.length) {
                    setGithubValue(value);
                    if (value !== githubValue) {
                        setTimeout(() => {
                            dispatch(searchCandidateByGithub(value)).then((res: any) => {
                                if (res?.error) {
                                    return context.createError({ message: res?.error?.message });
                                } else {
                                    if (res.payload.exist) {
                                        setExistId(res.payload.id || "")
                                        if (errors && ((errors.urlGitHub && errors.urlGitHub.type === 'candidate-exist-github') || !errors.urlGitHub)) {
                                            setTimeout(() => {
                                                handleOpen();
                                            }, 200)
                                            return false
                                        }
                                    } else {
                                        setExistId("");
                                        return true
                                    }
                                }
                            })
                        }, 700)
                    }
                } else {
                    clearErrors("urlGitHub");
                    return true
                }
                return !(existId && existId.length);
            }),
        comment: yup.string().required(texts.add.requiredComment).matches(noCyrillicRegex, texts.add.noCyrillic),
    }).required();

    const { control, formState: { errors, isValid }, reset, clearErrors } = useForm({
        resolver: yupResolver(AddCandidateSchema),
        mode: "onBlur",
    });
    const { rating } = useSelector((state: RootState) => state.candidates);

    const candidateSkills = RATING_IDS.map((item) => (
        {
            id: item,
            title: texts.add.rating[item as keyof typeof texts.add.rating]
        }))

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const addCandidateData = {
            name: data.get('candidateName'),
            position: data.get('position'),
            company: data.get('company'),
            linkedinUrl: data.get('urlLinkedIn'),
            facebookUrl: data.get('urlFacebook'),
            githubUrl: data.get('urlGitHub'),
            text: data.get('comment'),
            communication: rating.communication,
            coding: rating.coding,
            independence: rating.independence,
            teamwork: rating.teamwork,
            responsibility: rating.responsibility,
            photoUrl: "https://google.com",
        };
        dispatch(addCandidate(addCandidateData)).then((res: any) => {
            if (res?.error) {
                if (res?.error.message === texts.notifications.userUnauthorized) {
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: texts.notifications.refreshUnauthorized,
                        messageType: 'warning',
                    }));
                    navigate(ROUTES.signIn)
                } else {
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: res?.error?.message,
                        messageType: 'error',
                    }));
                }
            } else {
                dispatch(displayNotificationModal({
                    isOpen: true,
                    message: "Candidate was added",
                    messageType: 'success',
                }));
                reset(initialFormValues);
            }
        })
        dispatch(clearRating());
    };

    const handleOpenCandidate = () => {
        handleClose();
        navigate(`${ROUTES.candidate}/${existId}`)
    };

    return (
        <>
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "100%",
                    gap: "60px",
                    "@media screen and (max-width: 992px)": {
                        flexDirection: "column",
                    },
                }}
            >
                <Box sx={{ flexGrow: 1 }}>
                    <Controller
                        name="candidateName"
                        control={control}
                        render={({ field }) =>
                            <RaterInput
                                {...field}
                                type={INPUT_IDS.name}
                                placeholder={texts.add.name}
                                errorText={errors?.candidateName?.message}
                                marginBottomValue="20px"
                                required
                                autoFocus
                            />
                        }
                    />
                    <Controller
                        name="position"
                        control={control}
                        render={({ field }) =>
                            <RaterInput
                                {...field}
                                type={INPUT_IDS.position}
                                placeholder={texts.add.position}
                                errorText={errors?.position?.message}
                                marginBottomValue="20px"
                                required
                            />
                        }
                    />
                    <Controller
                        name="company"
                        control={control}
                        render={({ field }) =>
                            <RaterInput
                                {...field}
                                type={INPUT_IDS.company}
                                placeholder={texts.add.company}
                                errorText={errors?.company?.message}
                                marginBottomValue="20px"
                                required
                            />
                        }
                    />
                    <Controller
                        name="urlLinkedIn"
                        control={control}
                        render={({ field }) =>
                            <RaterInput
                                {...field}
                                type={INPUT_IDS.urlLinkedIn}
                                placeholder={texts.add.urlLinkedIn}
                                errorText={errors?.urlLinkedIn?.message}
                                marginBottomValue="20px"
                                required
                            />
                        }
                    />
                    <Controller
                        name="urlFacebook"
                        control={control}
                        render={({ field }) =>
                            <RaterInput
                                {...field}
                                type={INPUT_IDS.urlFacebook}
                                placeholder={texts.add.urlFacebook}
                                errorText={errors?.urlFacebook?.message}
                                marginBottomValue="20px"
                            />
                        }
                    />
                    <Controller
                        name="urlGitHub"
                        control={control}
                        render={({ field }) =>
                            <RaterInput
                                {...field}
                                type={INPUT_IDS.urlGitHub}
                                placeholder={texts.add.urlGitHub}
                                errorText={errors?.urlGitHub?.message}
                                marginBottomValue="20px"
                            />
                        }
                    />
                    <Controller
                        name="comment"
                        control={control}
                        render={({ field }) =>
                            <RaterInput
                                {...field}
                                type={INPUT_IDS.comment}
                                placeholder={texts.add.comment}
                                errorText={errors?.comment?.message}
                                required
                            />
                        }
                    />
                </Box>
                <Box sx={{
                    alignSelf: "center",
                    flexBasis: "330px",
                }}>
                    <RaterRating rows={candidateSkills} />
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "40px",
                    }}>
                        <RaterButton
                            title={texts.add.submitBtn}
                            disabled={!isValid}
                            type="submit"
                        />
                    </Box>
                </Box>
            </Box>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    ".MuiPaper-root": {
                        borderRadius: "15px",
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" sx={{ mr: 2, fontSize: theme.font.size.regular }}>
                    {texts.add.modal.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {texts.add.modal.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center", marginBottom: "15px" }}>
                    <RaterButton
                        size="small"
                        clickHandler={handleOpenCandidate}
                        title={texts.add.modal.openProfileBtn}
                        customStyles={{ textTransform: "none" }}
                    />
                    <RaterButton
                        size="small"
                        clickHandler={handleClose}
                        title={texts.add.modal.closeBtn}
                        customStyles={{ textTransform: "none" }}
                    />
                </DialogActions>
            </Dialog>
        </>
    )
}