import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAllUsers } from "../thunks/loginThunk";
import { IManager } from "../../types/user.types";

export interface IStore {
  users: IManager[] | [];
  loading: boolean;
}

const initialState: IStore = {
  users: [],
  loading: false,
};

const ManagersSlice = createSlice({
  name: "managers",
  initialState,
  reducers: {},
  extraReducers: {
    [getAllUsers.pending.type]: (
      state: IStore,
      action: PayloadAction<IManager[]>
    ) => {
      state.loading = true;
      state.users = action.payload;
    },
    [getAllUsers.fulfilled.type]: (
      state: IStore,
      action: PayloadAction<IManager[]>
    ) => {
      state.loading = false;
      state.users = action.payload;
    },
  },
});
// export const { setLoader } = ManagersSlice.actions;

export default ManagersSlice.reducer;