import Box from "@mui/system/Box"
import { INPUT_IDS, SEARCH_TYPES } from "../../constants"
import { texts } from "../../assets/texts"
import { RaterInput } from "../UI/RaterInput/RaterInput"
import { theme } from "../../assets/theme"
import IconButton from "@mui/material/IconButton"
import Magnify from '../../assets/icons/magnify.svg'
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../store/store"
import { searchCandidateGeneral } from "../../store/thunks/candidateThunk"
import { displayNotificationModal } from "../../store/slices/UiSlice"
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { searchCompaniesGeneral } from "../../store/thunks/companiesThunk"

const noCyrillicRegex: RegExp = /^[^а-яА-ЯёЁ]+$/;
const SearchSchema = yup.object({
    search: yup.string().matches(noCyrillicRegex, texts.add.noCyrillic),
}).required();

export const RaterSearch: React.FC<{ placeholder: string, type: string }> = ({ placeholder, type }) => {
    const dispatch = useDispatch<AppDispatch>();

    const { control, formState: { errors } } = useForm({
        resolver: yupResolver(SearchSchema),
        mode: "onBlur",
    });

    const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const newSearchValue = data.get('search') || "";
        if (type === SEARCH_TYPES.candidates) {
            dispatch(searchCandidateGeneral(newSearchValue)).then((res: any) => {
                if (res?.error) {
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: res?.error?.message,
                        messageType: 'error',
                    }));
                }
            })
        }
        if (type === SEARCH_TYPES.companies) {
            dispatch(searchCompaniesGeneral(newSearchValue)).then((res: any) => {
                if (res?.error) {
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: res?.error?.message,
                        messageType: 'error',
                    }));
                }
            })
        }
    };

    return (

        <Box component="form"
            onSubmit={handleSearch}
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                height: "100%",
            }}
        >
            <Box sx={{
                position: "relative",
            }}>
                <Controller
                    name="search"
                    control={control}
                    render={({ field }) =>
                        <RaterInput
                            {...field}
                            type={INPUT_IDS.search}
                            placeholder={placeholder}
                            errorText={errors?.search?.message}
                            autoFocus
                            customStyles={{
                                backgroundColor: "#ffffff",
                                minHeight: "40px",
                            }}
                        />
                    }
                />
                <IconButton
                    type="submit"
                    size="large"
                    sx={{
                        position: "absolute" as const,
                        right: 0,
                        top: "50%",
                        transform: "translateY(-50%)",
                        transition: "background 0.3s linear",
                        color: theme.font.color.main,
                        "&:hover": {
                            backgroundColor: theme.bg.activeLight,
                            transition: "background 0.3s linear",
                        },
                        "img": {
                            width: "40px",
                            height: "40px",
                        }
                    }}>
                    <img src={Magnify} alt="magnify" />
                </IconButton>
            </Box>
        </Box>
    )
}