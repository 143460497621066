import { SIGN_TYPES } from "./../../constants";
import styled from "styled-components";
import { theme } from "../../assets/theme";
import {
  SignFormContainerProps,
  SignFormTitleProps,
  SignLinkProps,
  SignTabsProps,
} from "./types";

export const SignContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const SignTabs = styled("div")<SignTabsProps>(({ type }) => ({
  height: "412px",
  backgroundColor:
    type === SIGN_TYPES.IN ? theme.bg.signDark : theme.bg.signLight,
  borderRadius: "60px",
  display: "flex",
}));

export const SignLink = styled("div")<SignLinkProps>(({ type }) => ({
  padding: type === SIGN_TYPES.IN ? "30px 15px 30px 30px" : "30px 30px 30px 15px",
  order: type === SIGN_TYPES.IN ? 0 : 2,
}));

export const SignFormContainer = styled("div")<SignFormContainerProps>(
  ({ type }) => ({
    backgroundColor:
      type === SIGN_TYPES.IN ? theme.bg.signLight : theme.bg.signDark,
    borderRadius: "60px",
    width: "400px",
    padding: "30px",
    color: theme.font.color.second,
  })
);

export const SignFormTitle = styled("div")<SignFormTitleProps>(({ type }) => ({
  textAlign: type === SIGN_TYPES.IN ? "right" : "left",
  color:
    type === SIGN_TYPES.IN ? theme.font.color.main : theme.font.color.light,
}));
