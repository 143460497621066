import styled from "styled-components";

export const FooterContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: "1180px",
  padding: "20px",
  gap: "10px",
  height: "92px",
  margin: "0 auto",
});

export const FooterPowered = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "20px",
});

export const FooterLogo = styled("span")({
  height: "22px",
  img: {
    height: "100%",
  },
});
