import { StageProps } from "../types/companies.types";

export const getFormStages = (data: FormData , stages: StageProps[]) => {
    const stagesFromForm = stages.map((stage, index) => {
        const newStage = {
            stageName: data.get(`stageName-${index + 1}`),
            shortDescr: data.get(`stageShortDescription-${index + 1}`),
            description: data.get(`stageDescription-${index + 1}`),
            days: Number(data.get(`days-${index + 1}`)),
            hours: Number(data.get(`hours-${index + 1}`)),
        }
        return newStage;
    })
    return stagesFromForm;
  }