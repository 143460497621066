import styled from "styled-components";
import { theme } from "../../assets/theme";

export const Wrapper = styled("div")({
  minHeight: "calc(100vh - 60px - 92px)",
  maxWidth: "1200px",  
  padding: "50px 0 30px 0",
});

export const AddCompanyContainer = styled("div")({
  backgroundColor: theme.bg.dark,
  borderRadius: "40px",
  padding: "40px",
});

export const StageFormHeader = styled("div")({
  backgroundColor: theme.bg.active,
  color: theme.font.color.white,
  padding: "10px",
  borderRadius: "15px",
});

export const StageFormInner = styled("div")({
  display: "flex",
  alignItems: "center",
  "@media screen and (max-width: 992px)": {
    flexDirection: "column",
  },
});

export const StageFormMain = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  marginTop: "20px",
  marginBottom: "20px",
});

export const StageFormTime = styled("div")({
  textAlign: "center",
  padding: "50px",
});

export const StageFormTimeTitle = styled("div")({
  marginBottom: "20px",
  textAlign: "center",
});
