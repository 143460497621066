import styled from "styled-components";
import { theme } from "../../assets/theme";

export const CommentsListContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
});

export const CommentsListItem = styled("div")<{moderating?: boolean}>(({ moderating }) => ({
  padding: "15px",
  background: moderating ? theme.bg.activeLight : theme.bg.second,
  borderRadius: "15px",
}));

export const CommentsListTitle = styled("div")({
  fontWeight: 600,
});

export const CommentsListDate = styled("div")({
  color: theme.font.color.second,
  fontSize: theme.font.size.small,
});

export const CommentsListContent = styled("div")({
  marginLeft: "30px",
  overflow: "hidden",
  lineHeight: 1,
  paddingBottom: "3px",
  wordBreak: "break-word",
});

export const Stages = styled("div")({
  margin: "5px 0 5px 30px",
  fontSize: theme.font.size.small,
});

export const StageName = styled("div")({
  color: theme.bg.active,
  fontWeight: 600,
});

export const Stage = styled("div")({
  marginBottom: "10px",
  padding: "10px",
  borderRadius: "15px",
  backgroundColor: theme.bg.main,
  display: "flex",
  gap: "5px",
  justifyContent: "space-between",
  alignItems: "center",
});

export const StageInfo = styled("div")({
  backgroundColor: theme.bg.main,
  display: "flex",
  gap: "5px",
  flexDirection: "column",
  flex: 1,
});

export const StageDateTime = styled("div")({
  display: "flex",
  gap: "10px",
  flexDirection: "column",
  flexBasis: "120px",
});

export const StageDateTimeTitle = styled("div")({
  fontWeight: 600,
});

export const DeleteCommentButton = styled("div")({
  color: theme.font.color.main,
  opacity: .5,
  width: "15px",
  flexShrink: 0,
  "&:hover": {
      transition: "background 0.3s linear",
      opacity: .7,
      cursor: "pointer",
  },
  "img": {
      width: "100%",
      transition: "background 0.3s linear",
  }
});
