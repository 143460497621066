import { Box } from "@mui/material"
import { CompaniesList } from "../components/CompaniesList/CompaniesList"
import { RaterSearch } from "../components/Search/RaterSearch"
import { RaterButton } from "../components/UI/RaterButton/RaterButton"
import { BTN_ICON_TYPES, ROUTES, SEARCH_TYPES } from "../constants"
import { texts } from "../assets/texts"
import { theme } from "../assets/theme"
import { useNavigate } from "react-router-dom"

export const CompaniesPage: React.FC = () => {
    const navigate = useNavigate();

    const handleAddCompany = () => {
        navigate(ROUTES.addCompany);
    }

    return (
        <Box>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                "@media screen and (max-width: 768px)": {
                    flexDirection: "column",
                    gap: 0,
                    marginBottom: "30px",
                },
            }}
            >
                <Box sx={{
                    flex: 1,
                    minWidth: "300px",
                    width: "100%",
                }}>
                    <Box sx={{
                        marginTop: "8vh",
                        marginBottom: "8vh",
                        "@media screen and (max-width: 768px)": {
                            marginBottom: "30px",
                        },
                    }}>
                        <RaterSearch placeholder={texts.search.companies} type={SEARCH_TYPES.companies} />
                    </Box>
                </Box>
                <RaterButton
                    title={texts.companies.newCompanyBtn}
                    startIconType={BTN_ICON_TYPES.add}
                    clickHandler={handleAddCompany}
                    customStyles={{
                        textTransform: "none",
                        fontSize: theme.font.size.medium,
                        height: "max-content",
                        padding: "0 20px",
                    }}
                />
            </Box>
            <CompaniesList />
        </Box>
    )
}