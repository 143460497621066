import Box from "@mui/material/Box"
import { Sign } from "../components/Sign/Sign"
import { SignInForm } from "../components/Sign/SignInForm/SignInForm"
import { SIGN_TYPES } from "../constants"

export const SignIn: React.FC = () => {
    return (
        <Box sx={{
            height: "calc(100vh - 60px - 92px)",
            display: "flex", 
            alignItems: "center",
            justifyContent: "center",
        }}>
            <Sign type={SIGN_TYPES.IN}>
                <SignInForm />
            </Sign>
        </Box>
    )
}