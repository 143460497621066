import Box from "@mui/material/Box"
import { InviteUserForm } from "../components/InviteUserForm/InviteUserForm"
import { UsersList } from "../components/UsersList/UsersList"

export const AdminUsers: React.FC = () => {
    return (
        <Box sx={{ minHeight: "calc(100vh - 60px - 92px)", display: "flex", flexDirection: "column"}}>
            <InviteUserForm />
            <UsersList />
        </Box>
    )
}