import { texts } from "../../assets/texts"
import { CompaniesTitle } from "./CompaniesList.styles"
import { CompaniesTable } from "./CompaniesTable"
import { CompaniesListProps } from "./types"

export const CompaniesList: React.FC<CompaniesListProps> = ({ title, moderation }) => {
    return (
        <>
            {title && <CompaniesTitle>{texts.companies.moderationTitle}</CompaniesTitle>}
            <CompaniesTable  moderation={moderation}/>
        </>
    )
}