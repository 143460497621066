import Box from "@mui/material/Box";
import { texts } from "../../../assets/texts";
import { INPUT_IDS, ROUTES } from "../../../constants";
import { RaterInput } from "../../UI/RaterInput/RaterInput";
import { RaterButton } from "../../UI/RaterButton/RaterButton";
import { useLocation, useNavigate } from "react-router-dom";
import { signUpUserThunk } from "../../../store/thunks/signUpThunk";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { localStorageSet } from "../../../hooks/useLocalStorage";
import { displayNotificationModal } from "../../../store/slices/UiSlice";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const noCyrillicRegex: RegExp = /^[^а-яА-ЯёЁ]+$/;

const SignUpSchema = yup.object({
    candidateName: yup.string().required(texts.add.requiredName).matches(noCyrillicRegex, texts.add.noCyrillic),
    email: yup.string().required(texts.add.requiredEmail).email(texts.add.invalidEmail),
    password: yup.string()
        .required(texts.add.requiredPassword)
        .min(6, texts.add.invalidPasswordMin)
        .matches(/[0-9]/, texts.add.invalidPasswordNumber)
        .matches(/[a-z]/, texts.add.invalidPasswordLowercase)
        .matches(/[A-Z]/, texts.add.invalidPasswordUppercase)
}).required();

export const SignUpForm: React.FC = () => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const token = searchParams.get("token");
    const dispatch = useDispatch<AppDispatch>();

    const { control, formState: { errors, isValid } } = useForm({
        resolver: yupResolver(SignUpSchema),
        mode: "onBlur",
    });

    if (token) {
        localStorageSet("invite", token);
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const signUpData = {
            name: data.get('candidateName'),
            email: data.get('email'),
            password: data.get('password'),
        }
        if (token) {
            dispatch(signUpUserThunk({
                name: signUpData.name,
                email: signUpData.email,
                password: signUpData.password,
                token: token || "",
            })).then((res: any) => {
                    if (res?.error) {
                        dispatch(displayNotificationModal({
                            isOpen: true,
                            message: res?.error?.message,
                            messageType: 'error',
                        }));
                    } else {
                        dispatch(displayNotificationModal({
                            isOpen: true,
                            message: texts.notifications.createdUser,
                            messageType: 'success',
                        }));
                        navigate(ROUTES.signIn);
                        localStorage.removeItem("invite");
                    }
                })
        } else {
            dispatch(displayNotificationModal({
                isOpen: true,
                message: texts.notifications.noInvitation,
                messageType: 'warning',
            }));
        }
    };

    return (
        <Box component="form"
            onSubmit={handleSubmit}
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                height: "100%",
            }}
        >
            <Controller
                name="candidateName"
                control={control}
                render={({ field }) =>
                    <RaterInput
                        {...field}
                        type={INPUT_IDS.name}
                        placeholder={texts.sign.name}
                        errorText={errors?.candidateName?.message}
                        marginBottomValue="20px"
                        required
                        autoFocus
                    />
                }
            />
            <Controller
                name="email"
                control={control}
                render={({ field }) =>
                    <RaterInput
                        {...field}
                        type={INPUT_IDS.email}
                        placeholder={texts.sign.email}
                        errorText={errors?.email?.message}
                        marginBottomValue="20px"
                        required
                    />
                }
            />
            <Controller
                name="password"
                control={control}
                render={({ field }) =>
                    <RaterInput
                        {...field}
                        type={INPUT_IDS.password}
                        placeholder={texts.sign.password}
                        errorText={errors?.password?.message}
                        marginBottomValue="30px"
                        required
                    />
                }
            />
            <Box sx={{ margin: "0 auto" }}>
                <RaterButton
                    title={texts.sign.submit}
                    disabled={!isValid}
                    type="submit"
                />
            </Box>
        </Box>
    )
}