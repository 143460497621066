export const theme = {
  font: {
    size: {
      large: "32px",
      medium: "24px",
      regular: "20px",
      small: "14px",
    },
    color: {
        main: "#000000",
        second: "#686161",
        light: "#F8F8F8",
        active: "#66cfff",
        white: "#ffffff"
    }
  },
  bg: {
    main: "#D0D0D0",
    second: "#EDEBEB",
    dark: "#808080",
    signDark: "#313030",
    signLight: "#FEFEFE",
    active: "#124B74",
    activeLight: "rgba(102, 207, 255, .5)",
  },
  error: "#770303",
  errorLight: "rgb(104, 97, 97)",
  errorInpurBorder: "#ac0404",
  rating: {
    active: "#0088C9",
  }
};
