import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { localStorageSet } from "../../hooks/useLocalStorage";
import { REQUEST_ROUTES } from "../../constants";
import { IUserLoginResponse } from "../../types/user.types";
import { api } from "../api";
import { rejectWithValue } from "../helpers";

export const loginUser = createAsyncThunk(
  REQUEST_ROUTES.logIn,
  async ({
    email,
    password,
  }: {
    email: FormDataEntryValue | null;
    password: FormDataEntryValue | null;
  }) => {
    try {
      const login = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}${REQUEST_ROUTES.logIn}`,
        { email, password },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      if (login && login.data) {
        localStorageSet("isLogin", true);
        localStorageSet("role", login.data.role);
        localStorageSet("id", login.data.id);
        localStorageSet("name", login.data.name);
        localStorageSet("email", login.data.email || "");
        return login.data as IUserLoginResponse;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const logoutUser = createAsyncThunk(
  REQUEST_ROUTES.logOut,
  async (id: string) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}${REQUEST_ROUTES.logOut}`,
        {
          id: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      localStorageSet("isLogin", false);
      localStorageSet("role", null);
      localStorageSet("name", null);
      localStorageSet("id", null);
      return response.data;
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.setItem("isLogin", "false");
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getAllUsers = createAsyncThunk(REQUEST_ROUTES.users, async () => {
  try {
    const response = await api.get(REQUEST_ROUTES.users);
    return response.data;
  } catch (error: any) {
    if (error.response.status === 401) {
      localStorage.setItem("isLogin", "false");
    }
    return rejectWithValue(error.response.data.message);
  }
});

export const deleteUser = createAsyncThunk(
  REQUEST_ROUTES.users,
  async (id: string) => {
    try {
      const response = await api.delete(`${REQUEST_ROUTES.users}/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const refreshToken = createAsyncThunk(
  REQUEST_ROUTES.refreshToken,
  async () => {
    try {
      const response = await api.get(REQUEST_ROUTES.refreshToken);
      return response.data;
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.setItem("isLogin", "false");
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const sendEmailToResetPass = createAsyncThunk(
  REQUEST_ROUTES.sendChangePassword,
  async ({
    email,
    link,
  }: {
    email: FormDataEntryValue | null;
    link: string;
  }) => {
    try {
      const response = await api.post(REQUEST_ROUTES.sendChangePassword, {
        email,
        link,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const changePassword = createAsyncThunk(
  REQUEST_ROUTES.changePassword,
  async ({
    email,
    newPassword,
    token,
  }: {
    email: FormDataEntryValue | null;
    newPassword: FormDataEntryValue | null;
    token: string;
  }) => {
    try {
      const response = await api.post(REQUEST_ROUTES.changePassword, {
        email,
        newPassword,
        token,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
