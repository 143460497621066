import styled from "styled-components";
import { theme } from "../../assets/theme";
import { Rating } from "@mui/material";

export const RaterRatingContainer = styled("div")<{ isCommentType?: boolean }>(
  ({ isCommentType }) => ({
    backgroundColor: theme.bg.second,
    marginBottom: isCommentType ? "0" : "40px",
    padding: isCommentType ? "15px" : "25px 25px 35px",
    borderRadius: "15px",
    "@media screen and (max-width: 992px)": {
      marginBottom: 0,
    },
  })
);

export const RaterRatingTitle = styled("div")({
  fontWeight: 600,
  marginBottom: "30px",
  textAlign: "center",
});

export const RaterRatingRows = styled("div")<{ isCommentType?: boolean }>(
  ({ isCommentType }) => ({
    display: "flex",
    flexDirection: "column",
    gap: isCommentType ? "5px" : "25px",
  })
);

export const RaterRatingRow = styled("div")({
  display: "flex",
  position: "relative",
  flexWrap: "wrap",
});

export const RaterRatingProgress = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: theme.rating.active,
  },
  "& .MuiRating-iconHover": {
    color: theme.rating.active,
  },
});

export const RaterRatingCommentsAmount = styled("div")({
  textAlign: "center",
  width: "118px",
});

export const RaterRatingRowTitle = styled("div")({});
