import { texts } from "../../assets/texts"
import { UsersListTitle } from "./UsersList.styles"
import { UsersTable } from "./UsersTable"

export const UsersList: React.FC = () => {
    return (
        <div>
            <UsersListTitle>{texts.admin.table.titleUsers}</UsersListTitle>
            <UsersTable />
        </div>
    )
}