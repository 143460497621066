import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAllCandidates, searchCandidateGeneral } from "../thunks/candidateThunk";
import { ICandidate } from "../../types/candidate.types";
import { CandidateRatingProps, CandidateRatingSkillProps } from "../../components/AddCandidate/AddCandidateForm.tsx/types";

export interface IStore {
  candidates: ICandidate[] | [];
  loading: boolean;
  rating: CandidateRatingProps;
}

export const initialRating = {
  communication: 0,
  coding: 0,
  independence: 0,
  teamwork: 0,
  responsibility: 0,
};

const initialState: IStore = {
  candidates: [],
  loading: false,
  rating: initialRating,
};

const CandidateSlice = createSlice({
  name: "candidates",
  initialState,
  reducers: {
    setRating: (
      state: IStore,
      action: PayloadAction<CandidateRatingSkillProps>
    ) => {
      const { id, value } = action.payload;
      state.rating = { ...state.rating, [id]: value };
    },
    clearRating: (state: IStore) => {
      state.rating = initialRating;
      state.loading = false;
    },
  },
  extraReducers: {
    [getAllCandidates.pending.type]: (
      state: IStore,
      action: PayloadAction<ICandidate[]>
    ) => {
      state.loading = true;
      state.candidates = action.payload;
    },
    [getAllCandidates.fulfilled.type]: (
      state: IStore,
      action: PayloadAction<ICandidate[]>
    ) => {
      state.loading = false;
      state.candidates = action.payload;
    },
    [searchCandidateGeneral.pending.type]: (
      state: IStore,
      action: PayloadAction<ICandidate[]>
    ) => {
      state.loading = true;
      state.candidates = action.payload;
    },
    [searchCandidateGeneral.fulfilled.type]: (
      state: IStore,
      action: PayloadAction<ICandidate[]>
    ) => {
      state.loading = false;
      state.candidates = action.payload;
    },
  },
});

export const { setRating, clearRating } = CandidateSlice.actions;

export default CandidateSlice.reducer;
