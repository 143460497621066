import styled from "styled-components";
import { theme } from "../../assets/theme";

export const CompanyCommentFormContainer = styled("div")({
  backgroundColor: theme.bg.second,
  borderRadius: "15px",
  display: "flex",
});

export const CompanyCommentFormMain = styled("div")({
  padding: "15px",
  flex: 1,
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
});

export const CompanyCommentFormRating = styled("div")({
  borderLeft: "1px solid #000000",
});
