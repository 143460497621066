import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICandidate } from "../../types/candidate.types";
import { getCandidateById } from "../thunks/candidateThunk";

export interface CandidateStateProps {
  candidateById: ICandidate;
  loading: boolean | any;
  id: string;
}

const initialState: CandidateStateProps = {
  candidateById: {
    id: "",
    name: "",
    position: "",
    linkedinUrl: "",
    githubUrl: "",
    facebookUrl: "",
    rating: "",
    comments: [],
    createdAt: "",
  },
  loading: false,
  id: "",
};

const CurrentCandidateSlice = createSlice({
  name: "candidateById",
  initialState,
  reducers: {},
  extraReducers: {
    [getCandidateById.pending.type]: (
      state: CandidateStateProps,
      action: PayloadAction<ICandidate>
    ) => {
      state.loading = true;
      state.candidateById = action.payload;
    },
    [getCandidateById.fulfilled.type]: (
      state: CandidateStateProps,
      action: PayloadAction<ICandidate>
    ) => {
      state.loading = false;
      state.candidateById = action.payload;
    },
  },
});

export default CurrentCandidateSlice.reducer;
