import { SetStateAction, useContext } from "react";
import { LayoutContext } from "../../Layout/Layout";
import { IconButton } from "@mui/material";
import { theme } from "../../../assets/theme";
import Close from '../../../assets/icons/close.svg';

export const DeleteButton: React.FC<{ id: SetStateAction<string> }> = ({ id }) => {
    const { handleOpen, setIdToDelete } = useContext(LayoutContext);

    return (
        <IconButton
            size="large"
            onClick={(event) => {
                event.stopPropagation();
                handleOpen();
                setIdToDelete(id);
            }}
            sx={{
                transition: "background 0.3s linear",
                color: theme.font.color.main,
                "&:hover": {
                    backgroundColor: theme.bg.activeLight,
                    transition: "background 0.3s linear",
                },
                "img": {
                    width: "22px",
                    height: "22px",
                }
            }}>
            <img src={Close} alt="X letter" />
        </IconButton>
    )
}