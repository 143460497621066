import styled from "styled-components";
import { theme } from "../../../assets/theme";

export const RaterInputError = styled("div")<{ light?: boolean }>(
  ({ light }) => ({
    fontSize: theme.font.size.small,
    color: light ? theme.errorLight : theme.error,
    position: "absolute",
    left: 0,
    bottom: "-15px",
    whiteSpace: "nowrap",
  })
);
