export const formateDate = (value: string) => {
  const dateInMs = new Date(Date.parse(value));
  const day =
    dateInMs.getDate() > 0 && dateInMs.getDate() < 10
      ? "0" + dateInMs.getDate()
      : dateInMs.getDate();
  const month =
    dateInMs.getMonth() + 1 > 0 && dateInMs.getMonth() + 1 < 10
      ? "0" + (dateInMs.getMonth() + 1)
      : dateInMs.getMonth() + 1;
  const formattedDate = day + "." + month + "." + dateInMs.getFullYear();
  return formattedDate;
};
