import { useState } from "react"
import { CompanyCommentFormContainer, CompanyCommentFormMain, CompanyCommentFormRating } from "./CompanyCommentForm.styles"
import { Box } from "@mui/material"
import { BTN_ICON_TYPES, COMPANY_RATING_IDS, INPUT_IDS, RATING_TYPES, ROLES } from "../../constants"
import { texts } from "../../assets/texts"
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../store/store"
import { RaterInput } from "../UI/RaterInput/RaterInput"
import { RaterRating } from "../RaterRating/RaterRating"
import { RaterButton } from "../UI/RaterButton/RaterButton"
import { theme } from "../../assets/theme"
import { displayNotificationModal } from "../../store/slices/UiSlice"
import { useParams } from "react-router-dom"
import { localStorageGet } from "../../hooks/useLocalStorage"
import { StageProps } from "../../types/companies.types"
import { StageFormHeader, StageFormInner, StageFormMain, StageFormTime, StageFormTimeTitle } from "../AddCompany/AddCompany.styles"
import { cancelLastStage, clearCompanyRating, clearStages, createNewStage } from "../../store/slices/CompaniesSlice"
import { getFormStages } from "../../helpers/stages"
import { createCompanyComment, getCompanyById } from "../../store/thunks/companiesThunk"

const noCyrillicRegex: RegExp = /^[^а-яА-ЯёЁ]+$/;

const AddCommentSchema = yup.object({
    name: yup.string().required(texts.add.requiredName).matches(noCyrillicRegex, texts.add.noCyrillic),
    comment: yup.string().required(texts.add.requiredComment).matches(noCyrillicRegex, texts.add.noCyrillic),
}).required();

const initialCompanyFormValues = {
    name: "",
    comment: "",
}

export const CompanyCommentForm: React.FC = () => {
    const [isFormActive, setIsFormActive] = useState(false);
    const { stages, rating } = useSelector((state: RootState) => state.companies);
    const { companyId } = useParams();
    const role = localStorageGet("role");
    const userEmail = localStorageGet("email");

    const dispatch = useDispatch<AppDispatch>();
    const { control, formState: { errors, isValid }, reset } = useForm({
        resolver: yupResolver(AddCommentSchema),
        mode: "onBlur",
    });

    const companySkills = COMPANY_RATING_IDS.map((item) => (
        {
            id: item,
            title: texts.addCompany.rating[item as keyof typeof texts.addCompany.rating]
        }
    ))

    const handleOnFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setIsFormActive(true)
    }

    const handleAddNewStage = () => {
        dispatch(createNewStage());
    }

    const handleCancelLastStage = () => {
        dispatch(cancelLastStage());
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const AddCompanyCommentData = {
            userName: data.get('name'),
            companyId: companyId || "",
            text: data.get('comment'),
            communication: rating.communication,
            timeManagement: rating.timeManagement,
            flexibility: rating.flexibility,
            expertise: rating.expertise,
            quality: rating.quality,
            stages: stages.length ? getFormStages(data, stages) : [],
            draft: role !== ROLES.admin,
            replyToEmail: userEmail,
        };

        dispatch(createCompanyComment(AddCompanyCommentData)).then((res: any) => {
            if (res?.error) {
                dispatch(displayNotificationModal({
                    isOpen: true,
                    message: res?.error?.message,
                    messageType: 'error',
                }));
            } else {
                dispatch(displayNotificationModal({
                    isOpen: true,
                    message: role === ROLES.admin ? texts.notifications.createAdminCommentSuccess: texts.notifications.createCommentSuccess,
                    messageType: 'success',
                }));
                reset(initialCompanyFormValues);
                if (companyId) {
                    dispatch(getCompanyById(companyId))
                }
            }
        })
        dispatch(clearCompanyRating());
        dispatch(clearStages());
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
        >
            <CompanyCommentFormContainer>
                <CompanyCommentFormMain>
                    <Controller
                        name="comment"
                        control={control}
                        render={({ field }) =>
                            <RaterInput
                                {...field}
                                type={INPUT_IDS.comment}
                                placeholder={texts.candidate.addComment.comment}
                                errorText={errors?.comment?.message}
                                required
                                small
                                handleOnFocus={handleOnFocus}
                                customStyles={{
                                    backgroundColor: "#ffffff",
                                    minHeight: "40px",
                                }}
                            />
                        }
                    />
                    {isFormActive &&
                        <>
                            <Box sx={{ marginTop: "15px" }}>
                                <Controller
                                    name="name"
                                    control={control}
                                    render={({ field }) =>
                                        <RaterInput
                                            {...field}
                                            type={INPUT_IDS.name}
                                            placeholder={texts.companies.name}
                                            errorText={errors?.name?.message}
                                            marginBottomValue="0"
                                            required
                                            small
                                            customStyles={{
                                                backgroundColor: "#ffffff",
                                                marginBottom: "0",
                                            }}
                                        />
                                    }
                                />
                            </Box>
                            {!!!stages.length &&
                                <Box mt={2}>
                                    <RaterButton
                                        title={texts.candidate.addComment.create}
                                        disabled={!isValid}
                                        type="submit"
                                        customStyles={{
                                            backgroundColor: theme.bg.main,
                                            padding: "10px",
                                            fontSize: "20px",
                                            lineHeight: "normal",
                                            height: "40px",
                                            textTransform: "none",
                                        }}
                                    />
                                </Box>
                            }
                        </>
                    }
                </CompanyCommentFormMain>
                {isFormActive &&
                    <CompanyCommentFormRating>
                        <RaterRating rows={companySkills} isCommentType ratingType={RATING_TYPES.company} />
                    </CompanyCommentFormRating>
                }
            </CompanyCommentFormContainer>
            {isFormActive &&
                <Box>
                    {role === ROLES.admin &&
                        <>
                            <Box sx={{ marginTop: "5px" }}>
                                {stages.map((stage: StageProps, index: number) => {
                                    return (
                                        <Box>
                                            <StageFormHeader>
                                                {`${texts.stage.title} ${index + 1}`}
                                            </StageFormHeader>
                                            <StageFormInner>
                                                <StageFormMain>
                                                    <RaterInput
                                                        type={`${INPUT_IDS.stageName}-${index + 1}`}
                                                        placeholder={texts.stage.namePlaceholder}
                                                        // errorText={errors?.stageName?.message}
                                                        required
                                                    />
                                                    <RaterInput
                                                        type={`${INPUT_IDS.stageShortDescription}-${index + 1}`}
                                                        placeholder={texts.stage.shortDescrPlaceholder}
                                                        // errorText={errors?.stageShortDescription?.message}
                                                        required
                                                    />
                                                    <RaterInput
                                                        type={`${INPUT_IDS.stageDescription}-${index + 1}`}
                                                        placeholder={texts.stage.descrPlaceholder}
                                                        // errorText={errors?.stageDescription?.message}
                                                        required
                                                    />
                                                </StageFormMain>
                                                <StageFormTime>
                                                    <StageFormTimeTitle>{texts.stage.timeTitle}</StageFormTimeTitle>
                                                    <RaterInput
                                                        type={`${INPUT_IDS.days}-${index + 1}`}
                                                        placeholder={texts.stage.daysPlaceholder}
                                                        customStyles={{ width: "150px", marginBottom: "15px" }}
                                                    // errorText={errors?.stageDescription?.message}
                                                    />
                                                    <RaterInput
                                                        type={`${INPUT_IDS.hours}-${index + 1}`}
                                                        placeholder={texts.stage.hoursPlaceholder}
                                                        customStyles={{ width: "150px" }}
                                                    // errorText={errors?.stageDescription?.message}
                                                    />
                                                </StageFormTime>
                                            </StageFormInner>
                                        </Box>
                                    )
                                })}
                            </Box>
                            <Box sx={{
                                display: "flex",
                                gap: "10px",
                            }}>
                                <RaterButton
                                    title={texts.companies.newStageBtn}
                                    startIconType={BTN_ICON_TYPES.add}
                                    clickHandler={handleAddNewStage}
                                    size="small"
                                    customStyles={{
                                        textTransform: "none",
                                        fontSize: "18px",
                                        height: "max-content",
                                        padding: "0 20px",
                                    }} />
                                {!!stages.length &&
                                    <RaterButton
                                        title={texts.companies.cancelLastStageBtn}
                                        clickHandler={handleCancelLastStage}
                                        size="small"
                                        customStyles={{
                                            textTransform: "none",
                                            fontSize: "18px",
                                            height: "max-content",
                                            padding: "0 20px",
                                        }} />
                                }
                            </Box>
                            {!!stages.length &&
                                <>
                                    <Box mt={2} mb={2}>
                                        <RaterButton
                                            title={texts.candidate.addComment.createComment}
                                            disabled={!isValid}
                                            type="submit"
                                            customStyles={{
                                                padding: "10px",
                                                fontSize: "20px",
                                                lineHeight: "normal",
                                                height: "40px",
                                                textTransform: "none",
                                            }}
                                        />
                                    </Box>
                                </>
                            }
                        </>
                    }
                </Box>
            }
        </Box>
    )
}