import Box from '@mui/material/Box';
import { GridColDef } from '@mui/x-data-grid';
import { texts } from '../../assets/texts';
import { RaterDataGrid } from '../RatedDataGrid/RaterDataGrid';
import { AppDispatch, RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAllCandidates } from '../../store/thunks/candidateThunk';
import { CommentProps, ICandidate } from '../../types/candidate.types';
import CircularProgress from '@mui/material/CircularProgress';
import { localStorageGet } from "../../hooks/useLocalStorage";
import { ROLES, ROUTES, TABLE_TYPES } from '../../constants';
import { DeleteButton } from '../UI/DeleteButton/DeleteButton';
import { RaterRatingProgress } from '../RaterRating/RaterRating.styles';
import { calculateTotalRatingFromCommentsList } from '../../helpers/calculateRating';
import { formateDate } from '../../helpers/formateDate';
import { useNavigate } from 'react-router-dom';
import { displayNotificationModal } from '../../store/slices/UiSlice';

const columns: GridColDef[] = [
    {
        field: 'name',
        headerName: texts.admin.table.name,
        minWidth: 130,
    },
    {
        field: 'position',
        headerName: texts.admin.table.position,
        minWidth: 130,
    },
    {
        field: 'linkedinUrl',
        headerName: texts.admin.table.urlLinkedIn,
        minWidth: 120,

    },
    {
        field: 'comment',
        headerName: texts.admin.table.comment,
        flex: 1,
        minWidth: 120,
    },
    {
        field: 'rating',
        headerName: texts.admin.table.rating,
        minWidth: 150,
        renderCell: (params) => (
            <RaterRatingProgress
                name="common-rating"
                value={params.value}
                readOnly
                precision={0.5}
            />
        )
    },
    {
        field: 'createdAt',
        headerName: texts.admin.table.createdDate,
        minWidth: 100,
        renderCell: (params) => {
            return (
                <div>{formateDate(params.value)}</div>
            )
        }
    },
    {
        field: 'remove',
        headerName: '',
        width: 60,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => {
            return (
                <>
                    {localStorageGet("role") === ROLES.admin &&
                        <DeleteButton id={String(params.id)} />
                    }
                </>

            )
        }
    },
];

const createTable = (data: ICandidate[], moderation: boolean) => {
    let filteredCandidates = data;
    if (moderation) {
        filteredCandidates = data?.length ? data.filter((item) => {
            const candidateForModeration = item.comments.some((comment) => comment.draft)
            return candidateForModeration
        }) : [];
    }

    return filteredCandidates?.length ? filteredCandidates.map(item => {
        const { id, name, position, linkedinUrl, comments, createdAt } = item;
        let commentsList = Object.assign([] as any[], comments);
        const moderatedComments = commentsList.length ? commentsList?.filter((comment: CommentProps) => !comment.draft) : [];

        if (moderatedComments.length > 1) {
            moderatedComments.sort((a: CommentProps, b: CommentProps) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
        }

        return ({
            id: id,
            name: name,
            position: position,
            linkedinUrl: linkedinUrl,
            comment: moderatedComments.length ? moderatedComments[0].text : commentsList[commentsList.length - 1].text,
            rating: calculateTotalRatingFromCommentsList(moderatedComments) || 0,
            createdAt: createdAt,
        })
    }) : []
};

export const CandidateTable: React.FC<{ moderation?: boolean | undefined }> = ({ moderation }) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { candidates, loading } = useSelector((state: RootState) => state.candidates);
    const role = localStorageGet("role");
    let filteredCandidates: ICandidate[] = [];

    if (candidates) {
        if (role !== ROLES.admin) {
            if (candidates.length) {
                filteredCandidates = candidates?.filter((candidate) => {
                    const isCandidateNew = candidate?.comments?.length === 1 && candidate.comments && candidate.comments[0].draft;
                    return !isCandidateNew;
                })
            }
        } else {
            filteredCandidates = candidates;
        }
    }

    useEffect(() => {
        dispatch(getAllCandidates()).then((res: any) => {
            if (res?.error) {
                if (res?.error.message === texts.notifications.userUnauthorized) {
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: texts.notifications.refreshUnauthorized,
                        messageType: 'warning',
                    }));
                    navigate(ROUTES.signIn)
                }
            }
        });
    }, []);

    return (
        <Box sx={{
            height: 347,
            width: '100%',
        }}>{loading
            ? <CircularProgress color='inherit' />
            : <RaterDataGrid rows={createTable(filteredCandidates, !!moderation)} columns={columns} tableType={TABLE_TYPES.candidates} moderation={moderation} />
            }
        </Box>
    );
}
