import { configureStore } from '@reduxjs/toolkit'
import UserSlice from './slices/UserSlice';
import ManagerSlice from './slices/ManagerSlice';
import UiSlice from './slices/UiSlice';
import CandidateSlice from './slices/CandidateSlice';
import CurrentCandidateSlice from './slices/CurrentCandidateSlice';
import CompaniesSlice from './slices/CompaniesSlice';

export const store = configureStore({
  reducer: {
    login: UserSlice,
    users: ManagerSlice,
    ui: UiSlice,
    candidates: CandidateSlice,
    candidateById: CurrentCandidateSlice,
    companies: CompaniesSlice,
  },
})

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;