import Logo from "../../assets/images/logo.svg"
import { texts } from "../../assets/texts"
import { FooterContainer, FooterLogo, FooterPowered } from "./Footer.styles"

export const Footer: React.FC = () => {
    return (
        <FooterContainer>
            <FooterPowered>
                <span>{texts.footer.poweredBy}</span>
                <FooterLogo>
                    <img src={Logo} alt="Miteyda text" />
                </FooterLogo>
            </FooterPowered>
            <div>{texts.footer.copyright}</div>
        </FooterContainer>
    )
}