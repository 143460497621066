import { initialRating } from "../store/slices/CandidateSlice";
import { initialCompanyRating } from "../store/slices/CompaniesSlice";

export interface RatingParamsProps {
  coding: number;
  communication: number;
  independence: number;
  responsibility: number;
  teamwork: number;
}

export interface RatingCompaniesParamsProps {
  communication: number;
  timeManagement: number;
  flexibility: number;
  expertise: number;
  quality: number;
}

export const calculateTotalRatingFromCommentsList = (
  commentsList: RatingParamsProps[]
) => {
  const newRating = commentsList.length
    ? commentsList?.reduce((acc, item) => {
        const middleRating =
          (item.coding +
            item.communication +
            item.independence +
            item.responsibility +
            item.teamwork) /
          5;
        return acc + middleRating;
      }, 0)
    : 0;
  return (+newRating / commentsList.length).toFixed(1);
};

export const calculateCompanyTotalRatingFromCommentsList = (
  commentsList: RatingCompaniesParamsProps[]
) => {
  const newRating = commentsList.length
    ? commentsList?.reduce((acc, item) => {
        const middleRating =
          (item.communication +
            item.timeManagement +
            item.flexibility +
            item.expertise +
            item.quality) /
          5;
        return acc + middleRating;
      }, 0)
    : 0;
  return (+newRating / commentsList.length).toFixed(1);
};

export const calculateTotalRatingFromObject = (rating: RatingParamsProps) => {
  const ratingValuesList = Object.values(rating);
  const summary = Object.values(ratingValuesList).reduce(
    (acc, item) => acc + item,
    0
  );
  const totalRating = summary / ratingValuesList.length;
  return +totalRating.toFixed(1);
};

export const calculateCompanyMiddleRatingValues = (
  commentsList: RatingCompaniesParamsProps[]
) => {
  let paramsSummary = commentsList?.length
    ? commentsList?.reduce((acc, item) => {
        const resultObj = {
          communication: acc.communication + item.communication,
          timeManagement: acc.timeManagement + item.timeManagement,
          flexibility: acc.flexibility + item.flexibility,
          expertise: acc.expertise + item.expertise,
          quality: acc.quality + item.quality,
        };
        return resultObj;
      }, initialCompanyRating)
    : initialCompanyRating;

  const middleValuesObj = {
    communication: +(paramsSummary.communication / commentsList.length).toFixed(
      1
    ),
    timeManagement: +(
      paramsSummary.timeManagement / commentsList.length
    ).toFixed(1),
    flexibility: +(paramsSummary.flexibility / commentsList.length).toFixed(1),
    expertise: +(paramsSummary.expertise / commentsList.length).toFixed(1),
    quality: +(paramsSummary.quality / commentsList.length).toFixed(1),
  };
  return middleValuesObj;
};
export const calculateMiddleRatingValues = (
  commentsList: RatingParamsProps[]
) => {

  let paramsSummary = commentsList?.length ? commentsList?.reduce((acc, item) => {
    const resultObj = {
      communication: acc.communication + item.communication,
      independence: acc.independence + item.independence,
      teamwork: acc.teamwork + item.teamwork,
      responsibility: acc.responsibility + item.responsibility,
      coding: acc.coding + item.coding,
    };
    return resultObj;
  }, initialRating) : initialRating;

  const middleValuesObj = {
    communication: +(paramsSummary.communication / commentsList.length).toFixed(
      1
    ),
    independence: +(paramsSummary.independence / commentsList.length).toFixed(
      1
    ),
    teamwork: +(paramsSummary.teamwork / commentsList.length).toFixed(1),
    responsibility: +(
      paramsSummary.responsibility / commentsList.length
    ).toFixed(1),
    coding: +(paramsSummary.responsibility / commentsList.length).toFixed(1),
  };
  return middleValuesObj;
};
