import { AddCompanyContainer, Wrapper } from "./AddCompany.styles"
import { AddCompanyForm } from "./AddCompanyForm"

export const AddCompany: React.FC = () => {
    return (
        <Wrapper>
            <AddCompanyContainer>
                <AddCompanyForm />
            </AddCompanyContainer>
        </Wrapper>
    )
}