export const texts = {
  navigation: {
    candidates: "Candidates",
    companies: "Companies",
    add: "Add",
    aboutUs: "About Us",
    moderation: "Moderation",
  },
  header: {
    rater: "Rater",
    list: "Candidates",
    users: "Users",
    companies: "Companies",
    moderation: "Moderation",
    backToModeration: "Back to moderation",
    backToList: "Back to candidates",
  },
  footer: {
    poweredBy: "Powered by",
    copyright: "All rights reserved (c) 2022 - 2023",
  },
  sign: {
    signIn: "Sign In",
    signUp: "Sign Up",
    name: "Name",
    email: "Email",
    password: "Password",
    confirmPassword: "Confirm password",
    submit: "GO!",
    forgotPass: "Forgot password?",
    passwordsMustMatches: "Passwords should be identical",
  },
  forgotPassword: {
    emailFormTitle: "Forgot password",
    resetFormTitle: "Reset password",
    resetPasswordPlaceholder: "Password",
    resetPasswordConfirmPlaceholder: "Confirm password",
    submit: "Send",
    resetBtn: "Reset",
  },
  admin: {
    invite: "Invite user by email",
    emailPlaceholder: "Enter email",
    searchPlaceholder: "Search by name, linkedin or github link...",
    table: {
      titleUsers: "Active users",
      name: "Name:",
      email: "Email:",
      position: "Position:",
      comment: "Last comment:",
      urlLinkedIn: "LinkedIn URL:",
      rating: "Rating:",
      linkedIn: "Linkedin:",
      siteUrl : "Website:",
      createdDate: "Date:",
    },
  },
  search: {
    candidates: "Search by name, linkedin or github link...",
    companies: "Search by name, linkedin or website...",
  },
  aboutUs: `This website is created to unite companies and warn them about unscrupulous candidates. Here you can check the person you want to collaborate with and ensure that working with them will be as comfortable and safe as possible for you. To do this, you can switch to the "Search" tab and begin searching by name and surname. We would also appreciate it if you help other companies avoid negative experiences by adding candidates with whom you have had problems to the list. For this, you need to switch to the "Add" tab. If you have any questions, you can contact us at info@miteyda.com. We hope that by working together, we can make our work comfortable and safe.`,
  add: {
    name: "Name*",
    position: "Position*",
    company: "Company*",
    urlLinkedIn: "LinkedIn URL*",
    urlFacebook: "FaceBook URL",
    urlGitHub: "GitHub URL",
    comment: "Comment*",
    submitBtn: "Add",
    requiredName: "Name is required",
    requiredEmail: "Email is required",
    requiredPassword: "Password is required",
    requiredPosition: "Position is required",
    requiredCompany: "Company is required",
    requiredLinkedIn: "LinkedIn is required",
    requiredComment: "Comment is required",
    noCyrillic: "Latin letters, numbers and symbols are allowed",
    notContainLinkedIn: "Link should start with https://www.linkedin.com/ and min 1 letter after",
    notContainGithub: "Link should start with https://github.com/ and min 1 letter after",
    candidateExists: "This candidate already exists.",
    invalidUrl: "URL must have valid value",
    invalidEmail: "Email should be valid and contain @ symbol",
    invalidPasswordMin: "Password must contain 6 or more characters",
    invalidPasswordNumber: "Password requires a number",
    invalidPasswordLowercase: "Password requires a lowercase letter",
    invalidPasswordUppercase: "Password requires an uppercase letter",
    modal: {
      title: "This candidate already exists.",
      content: "Leave your comment on his profile or change link.",
      openProfileBtn: "Go to profile",
      closeBtn: "Close",
    },
    rating: {
      title: "Raiting",
      communication: "Communication",
      coding: "Coding",
      independence: "Independence",
      teamwork: "Teamwork",
      responsibility: "Responsibility",
      commentsAmount: "Feedbacks",
      feedbacksTooltip: "How many feedbacks was made",
    },
  },
  addCompany: {
    name: "Company name*",
    douUrl: "DOU URL*",
    djinniUrl: "Djinni URL *",
    linkedinUrl: "LinkedIn URL*",
    website: "Company website*",
    comment: "Comment*",
    requiredCompanyName: "Company name is required",
    requiredDou: "DOU URL is required",
    requiredDjinni: "Djinni URL is required",
    requiredLinkedIn: "LinkedIn is required",
    requiredWebsite: "Company website is required",
    requiredComment: "Comment is required",
    submitBtn: "Add company",
    companyExists: "This company already exists.",
    modal: {
      title: "This company already exists.",
      content: "Leave your comment on its profile or change link.",
      openProfileBtn: "Go to profile",
      closeBtn: "Close",
      nameExistsTitle: "We found companies with similar name.",
      nameExistsContent: "Check the list below. Leave your comment on profile or change link.",
    },
    rating: {
      title: "Raiting",
      communication: "Communication",
      timeManagement: "Time management",
      flexibility: "Flexibility",
      expertise: "Expertise",
      quality: "Quality of the product",
      commentsAmount: "Feedbacks",
      feedbacksTooltip: "How many feedbacks was made",
    },
  },
  candidateList: {
    question: "Are you sure you want to remove candidate?",
    explanations: "This action cannot be undone.",
    removeBtn: "Remove",
    cancelBtn: "Cancel",
    moderationTitle: "Candidates",
    newCandidateBtn: "New Candidate",
  },
  userList: {
    question: "Are you sure you want to remove user?",
    explanations: "This action cannot be undone.",
    removeBtn: "Remove",
    cancelBtn: "Cancel",
  },
  notifications: {
    createdUser: "User was created",
    noInvitation: "Only invitation allows you to sign up. Please check your email.",
    candidateDeleted: "Candidate was deleted",
    userDeleted: "User was deleted",
    createCommentSuccess: "Comment was successfully created and submitted for moderation. Answer will be sent to your email",
    createAdminCommentSuccess: "Comment was successfully created.",
    commentApproved: "Comment was successfully approved",
    commentDeclinedSuccess: "Comment was successfully declined",
    candidateApproved: "Candidate was successfully approved",
    candidateDeclined: "Candidate was successfully declined",
    companyApproved: "Company was approved",
    companyDeclined: "Company was declined",
    candidateExist: "Candidate already exists",
    companyAdded: "Company was successfully created",
    whyDeleteCompany: "Why you delete this company?",
    whyDeclineCompany: "Why you decline this company?",
    companyDeleted: "Company was deleted",
    companyCommentApproved: "Comment was approved",
    refreshUnauthorized: "Sign in, please.",
    userUnauthorized: "User is unauthorized",
    checkEmailToResetPass: "Check your email to reset password.",
    successResetPassword: "Password was changed. You can sign in with new password",
    deleteCommentQuestion: "Are you sure you want to remove comment?",
    deleteCommentExplanations: "This action cannot be undone.",
    deleteCommentRemoveBtn: "Remove",
    deleteCommentCancelBtn: "Cancel",
    successDeleteComment: "Comment was deleted",
    minLengthCompanyName: "Min 3 symbols",
  },
  candidate: {
    name: "Name:",
    position: "Position:",
    linkedinUrl: "Linkedin URL:",
    facebookUrl: "FaceBook URL:",
    githubUrl: "GitHub URL:",
    rating: {
      total: "Total",
    },
    addComment: {
      comment: "Add new comment",
      create: "Create",
      createComment: "Create Comment",
      company: "Enter company name"
    },
    moderation: {
      commentApprove: "Approve",
      commentDecline: "Decline",
      candidateApprove: "Approve candidate",
      candidateDecline: "Decline candidate",
      whyDeclineComment: "Why you decline this comment?",
      whyDeclineCandidate: "Why you decline this candidate?",
      whyDeleteCandidate: "Why you delete this candidate?",
      addExplanations: "Add explanations",
      sendExplanations: "Send",
      closeModal: "Close",
    }
  },
  companies: {
    moderationTitle: "Companies",
    newCompanyBtn: "New Company",
    newStageBtn: "Add new stage",
    addExplanations: "Add explanations",
    sendExplanations: "Send",
    closeModal: "Close",
    approveBtn: "Approve company",
    declineBtn: "Decline company",
    name: "Name",
    douUrl: "DOU URL",
    djinniUrl: "Djinni URL",
    linkedinUrl: "LinkedIn URL",
    website: "Website",
    cancelLastStageBtn: "Cancel last stage",
    rating: {
      total: "Total",
    },
    stages: {
      dateTimeTitle: "Time elapsed"
    }
  },
  stage: {
    title: "Stage",
    timeTitle: "Time elapsed:",
    namePlaceholder: "Stage name*",
    shortDescrPlaceholder: "Stage short discription*",
    descrPlaceholder: "Stage long discription*",
    daysPlaceholder: "days",
    hoursPlaceholder: "hrs",
    requiredStageName: "Stage name is required",
    requiredShortDescr: "Short description is required",
    requiredDescription: "Long description is required",
  }
};
