import Box from "@mui/material/Box";
import { texts } from "../../assets/texts";
import { RaterInput } from "../UI/RaterInput/RaterInput";
import { RaterButton } from "../UI/RaterButton/RaterButton";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from "react-hook-form";
import { addCompany, searchCompanyByLinkedIn, searchCompanyByName, searchCompanyBySite } from "../../store/thunks/companiesThunk";
import * as yup from "yup";
import { RaterRating } from "../RaterRating/RaterRating";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { LayoutContext } from "../Layout/Layout";
import { theme } from "../../assets/theme";
import { INPUT_IDS, COMPANY_RATING_IDS, RATING_TYPES, ROLES, BTN_ICON_TYPES, ROUTES } from "../../constants";
import { localStorageGet } from "../../hooks/useLocalStorage";
import { cancelLastStage, clearCompanyRating, clearStages, createNewStage } from "../../store/slices/CompaniesSlice";
import { CompanyProps, StageProps } from "../../types/companies.types";
import { StageFormHeader, StageFormInner, StageFormMain, StageFormTime, StageFormTimeTitle } from "./AddCompany.styles";
import { displayNotificationModal } from "../../store/slices/UiSlice";
import { useNavigate } from "react-router-dom";
import { getFormStages } from "../../helpers/stages";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import { RaterInputError } from "../UI/RaterInput/RaterInput.styles";

const noCyrillicRegex: RegExp = /^[^а-яА-ЯёЁ]+$/;

export const AddCompanyForm: React.FC = () => {
    const { open, handleClose, handleOpen } = useContext(LayoutContext);
    const [isExistsCompaniesModalOpen, setIsExistsCompaniesModalOpen] = useState(false);
    const handleOpenExistsCompaniesModal = () => setIsExistsCompaniesModalOpen(true);
    const handleCloseExistsCompaniesModal = () => setIsExistsCompaniesModalOpen(false);
    const role = localStorageGet("role");
    const userName = localStorageGet("name");
    const userEmail = localStorageGet("email");
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { stages } = useSelector((state: RootState) => state.companies);
    const [existId, setExistId] = useState('');
    const [existNameCompanies, setExistNameCompanies] = useState([]);
    const [nameValue, setNameValue] = useState("");
    const [siteValue, setSiteValue] = useState("");
    const [linkedInValue, setLinkedInValue] = useState("");
    const [currentCompanyNameError, setCurrentCompanyNameError] = useState("")

    const initialCompanyNameErrors = {
        required: "",
        minLength: "",
        noCyrillic: "",
        exists: "",
    }
    const [companyNameErrors, setCompanyNameErrors] = useState(initialCompanyNameErrors)

    const initialFormValues = {
        douUrl: "",
        djinniUrl: "",
        linkedinUrl: "",
        comment: "",
        website: "",
    }

    const AddCandidateSchema = yup.object({
        douUrl: yup.string()
            .required(texts.addCompany.requiredDou)
            .matches(noCyrillicRegex, texts.add.noCyrillic)
            .url(texts.add.invalidUrl),
        djinniUrl: yup.string().required(texts.addCompany.requiredDjinni).matches(noCyrillicRegex, texts.add.noCyrillic),
        linkedinUrl: yup.string()
            .required(texts.add.requiredLinkedIn)
            .matches(noCyrillicRegex, texts.add.noCyrillic)
            .url(texts.add.invalidUrl)
            .test('company-exist-linkedIn', texts.add.candidateExists, function (value, context) {
                if (value && value.length) {
                    setLinkedInValue(value);
                    if (value !== linkedInValue) {
                        setTimeout(() => {
                            dispatch(searchCompanyByLinkedIn(value)).then((res: any) => {
                                if (res?.error) {
                                    return context.createError({ message: res?.error?.message });
                                } else {
                                    if (res.payload.exist) {
                                        setExistId(res.payload.id || "")
                                        if (errors && ((errors.linkedinUrl && errors.linkedinUrl.type === 'company-exist-linkedIn') || !errors.linkedinUrl)) {
                                            setTimeout(() => {
                                                handleOpen();
                                            }, 200)
                                            return false
                                        }
                                    } else {
                                        setExistId("");
                                        return true
                                    }
                                }
                            })
                        }, 700)
                    }
                }
                return !(existId && existId.length);
            }),
        website: yup.string()
            .required(texts.addCompany.requiredWebsite)
            .matches(noCyrillicRegex, texts.add.noCyrillic)
            .url(texts.add.invalidUrl)
            .test('company-exist-website', texts.addCompany.companyExists, function (value, context) {
                if (value && value.length) {
                    setSiteValue(value);
                    if (value !== siteValue) {
                        setTimeout(() => {
                            dispatch(searchCompanyBySite(value)).then((res: any) => {
                                if (res?.error) {
                                    return context.createError({ message: res?.error?.message });
                                } else {
                                    if (res.payload.exist) {
                                        setExistId(res.payload.id || "")
                                        if (errors && ((errors.website && errors.website.type === 'company-exist-website') || !errors.website)) {
                                            setTimeout(() => {
                                                handleOpen();
                                            }, 200)
                                            return false
                                        }
                                    } else {
                                        setExistId("");
                                        return true
                                    }
                                }
                            })
                        }, 700)
                    }
                }
                return !(existId && existId.length);
            }),
        comment: yup.string()
            .required(texts.add.requiredComment)
            .matches(noCyrillicRegex, texts.add.noCyrillic),
        // stageName: yup.string()
        //     .required(texts.stage.requiredStageName)
        //     .matches(noCyrillicRegex, texts.add.noCyrillic),
        // stageShortDescription: yup.string()
        //     .required(texts.stage.requiredShortDescr)
        //     .matches(noCyrillicRegex, texts.add.noCyrillic),
        // stageDescription: yup.string()
        //     .required(texts.stage.requiredDescription)
        //     .matches(noCyrillicRegex, texts.add.noCyrillic),
    }).required();

    const { control, formState: { errors, isValid }, reset } = useForm({
        resolver: yupResolver(AddCandidateSchema),
        mode: "onBlur",
    });
    const { rating } = useSelector((state: RootState) => state.companies);

    const companyRatingParams = COMPANY_RATING_IDS.map((item) => (
        {
            id: item,
            title: texts.addCompany.rating[item as keyof typeof texts.addCompany.rating]
        }))

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const addCompanyData = {
            companyName: data.get('companyName'),
            douUrl: data.get('douUrl'),
            djinniUrl: data.get('djinniUrl'),
            linkedinUrl: data.get('linkedinUrl'),
            siteUrl: data.get('website'),
            text: data.get('comment'),
            stages: stages.length ? getFormStages(data, stages) : [],
            communication: rating.communication,
            timeManagement: rating.timeManagement,
            flexibility: rating.flexibility,
            expertise: rating.expertise,
            quality: rating.quality,
            userName: userName,
            replyToEmail: userEmail,
            draft: role !== ROLES.admin,
        }
        dispatch(addCompany(addCompanyData)).then((res: any) => {
            if (res?.error) {
                if (res?.error.message === texts.notifications.userUnauthorized) {
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: texts.notifications.refreshUnauthorized,
                        messageType: 'warning',
                    }));
                    navigate(ROUTES.signIn)
                } else {
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: res?.error?.message,
                        messageType: 'error',
                    }));
                }
            } else {
                dispatch(displayNotificationModal({
                    isOpen: true,
                    message: texts.notifications.companyAdded,
                    messageType: 'success',
                }));
                reset(initialFormValues);
            }
        })
        dispatch(clearCompanyRating());
        dispatch(clearStages());
    };

    const handleOpenCompany = () => {
        handleClose();
        navigate(`${ROUTES.company}/${existId}`)
    };
    const handleAddNewStage = () => {
        dispatch(createNewStage());
    }

    const handleCancelLastStage = () => {
        dispatch(cancelLastStage());
    }

    const handleOnBlurName = (e: React.FocusEvent<HTMLInputElement>) => {
        const currentValue = e.target.value;
        setCompanyNameErrors(initialCompanyNameErrors);
        if (currentValue && currentValue.length) {
            setNameValue(currentValue);
            if (currentValue !== nameValue) {
                if (currentValue.length <= 2) {
                    setCompanyNameErrors({ ...initialCompanyNameErrors, minLength: texts.notifications.minLengthCompanyName })
                } else {
                    if (!(noCyrillicRegex.test(currentValue))) {
                        setCompanyNameErrors({ ...initialCompanyNameErrors, noCyrillic: texts.add.noCyrillic })
                    } else {
                        dispatch(searchCompanyByName(currentValue)).then((res: any) => {
                            if (res?.error) {
                                dispatch(displayNotificationModal({
                                    isOpen: true,
                                    message: res?.error?.message,
                                    messageType: 'error',
                                }));
                            } else {
                                if (res.payload.companies && !!res.payload.companies.length) {
                                    setExistNameCompanies(res.payload.companies || [])
                                    handleOpenExistsCompaniesModal();
                                } else {
                                    setExistNameCompanies([]);
                                    return true
                                }
                            }
                        })
                    }
                }
            }
        } else {
            setCompanyNameErrors({ ...initialCompanyNameErrors, required: texts.addCompany.requiredCompanyName })
        }
    }

    useEffect(() => {
        setCurrentCompanyNameError(
            companyNameErrors.required ||
            companyNameErrors.minLength ||
            companyNameErrors.noCyrillic ||
            companyNameErrors.exists);
    }, [companyNameErrors])

    return (
        <>
            <Box
                component="form"
                onSubmit={handleSubmit}
            >
                <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "100%",
                    gap: "60px",
                    "@media screen and (max-width: 992px)": {
                        flexDirection: "column",
                    },
                }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Box sx={{ marginBottom: "20px", position: "relative" }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                type={INPUT_IDS.companyName}
                                placeholder={texts.addCompany.name}
                                name={INPUT_IDS.companyName}
                                autoComplete={INPUT_IDS.companyName}
                                autoFocus
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    rows: 1,
                                    onBlur: handleOnBlurName,
                                    style: {
                                        fontSize: 20,
                                        color: theme.font.color.second,
                                        fontFamily: "'Inter', sans-serif",
                                        padding: "10px",
                                        height: "fit-content",
                                        lineHeight: "50px",
                                    }
                                }}
                                sx={{
                                    backgroundColor: theme.bg.second,
                                    borderRadius: "15px",
                                    margin: 0,
                                    boxSizing: "border-box",
                                    border: currentCompanyNameError.length ? `2px solid ${theme.errorInpurBorder}` : "2px solid transparent",
                                }}
                            />
                            <RaterInputError>{currentCompanyNameError}</RaterInputError>
                        </Box>
                        <Controller
                            name="douUrl"
                            control={control}
                            render={({ field }) =>
                                <RaterInput
                                    {...field}
                                    type={INPUT_IDS.douUrl}
                                    placeholder={texts.addCompany.douUrl}
                                    errorText={errors?.douUrl?.message}
                                    marginBottomValue="20px"
                                    required
                                />
                            }
                        />
                        <Controller
                            name="djinniUrl"
                            control={control}
                            render={({ field }) =>
                                <RaterInput
                                    {...field}
                                    type={INPUT_IDS.djinniUrl}
                                    placeholder={texts.addCompany.djinniUrl}
                                    errorText={errors?.djinniUrl?.message}
                                    marginBottomValue="20px"
                                    required
                                />
                            }
                        />
                        <Controller
                            name="linkedinUrl"
                            control={control}
                            render={({ field }) =>
                                <RaterInput
                                    {...field}
                                    type={INPUT_IDS.linkedinUrl}
                                    placeholder={texts.addCompany.linkedinUrl}
                                    errorText={errors?.linkedinUrl?.message}
                                    marginBottomValue="20px"
                                    required
                                />
                            }
                        />
                        <Controller
                            name="website"
                            control={control}
                            render={({ field }) =>
                                <RaterInput
                                    {...field}
                                    type={INPUT_IDS.website}
                                    placeholder={texts.addCompany.website}
                                    errorText={errors?.website?.message}
                                    marginBottomValue="20px"
                                />
                            }
                        />
                        <Controller
                            name="comment"
                            control={control}
                            render={({ field }) =>
                                <RaterInput
                                    {...field}
                                    type={INPUT_IDS.comment}
                                    placeholder={texts.add.comment}
                                    errorText={errors?.comment?.message}
                                    required
                                />
                            }
                        />
                    </Box>
                    <Box sx={{
                        alignSelf: "center",
                        flexBasis: "330px",
                    }}>
                        <RaterRating rows={companyRatingParams} ratingType={RATING_TYPES.company} />
                        {!!!stages.length &&
                            <Box sx={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "40px",
                            }}>
                                <RaterButton
                                    title={texts.addCompany.submitBtn}
                                    disabled={!isValid}
                                    type="submit"
                                    customStyles={{
                                        fontSize: "24px",
                                        textTransform: "none",
                                    }}
                                />
                            </Box>
                        }
                    </Box>
                </Box>
                {role === ROLES.admin &&
                    <>
                        <Box sx={{ marginTop: "50px" }}>
                            {stages.map((stage: StageProps, index: number) => {
                                return (
                                    <Box>
                                        <StageFormHeader>
                                            {`${texts.stage.title} ${index + 1}`}
                                        </StageFormHeader>
                                        <StageFormInner>
                                            <StageFormMain>
                                                <RaterInput
                                                    type={`${INPUT_IDS.stageName}-${index + 1}`}
                                                    placeholder={texts.stage.namePlaceholder}
                                                    // errorText={errors?.stageName?.message}
                                                    required
                                                />
                                                <RaterInput
                                                    type={`${INPUT_IDS.stageShortDescription}-${index + 1}`}
                                                    placeholder={texts.stage.shortDescrPlaceholder}
                                                    // errorText={errors?.stageShortDescription?.message}
                                                    required
                                                />
                                                <RaterInput
                                                    type={`${INPUT_IDS.stageDescription}-${index + 1}`}
                                                    placeholder={texts.stage.descrPlaceholder}
                                                    // errorText={errors?.stageDescription?.message}
                                                    required
                                                />
                                            </StageFormMain>
                                            <StageFormTime>
                                                <StageFormTimeTitle>{texts.stage.timeTitle}</StageFormTimeTitle>
                                                <RaterInput
                                                    type={`${INPUT_IDS.days}-${index + 1}`}
                                                    placeholder={texts.stage.daysPlaceholder}
                                                    customStyles={{ width: "150px", marginBottom: "15px" }}
                                                // errorText={errors?.stageDescription?.message}
                                                />
                                                <RaterInput
                                                    type={`${INPUT_IDS.hours}-${index + 1}`}
                                                    placeholder={texts.stage.hoursPlaceholder}
                                                    customStyles={{ width: "150px" }}
                                                // errorText={errors?.stageDescription?.message}
                                                />
                                            </StageFormTime>
                                        </StageFormInner>
                                    </Box>
                                )
                            })}
                        </Box>
                        <Box sx={{
                            display: "flex",
                            gap: "10px",
                        }}>
                            <RaterButton
                                title={texts.companies.newStageBtn}
                                startIconType={BTN_ICON_TYPES.add}
                                clickHandler={handleAddNewStage}
                                size="small"
                                customStyles={{
                                    textTransform: "none",
                                    fontSize: "18px",
                                    height: "max-content",
                                    padding: "0 20px",
                                }} />
                            {!!stages.length &&
                                <RaterButton
                                    title={texts.companies.cancelLastStageBtn}
                                    clickHandler={handleCancelLastStage}
                                    size="small"
                                    customStyles={{
                                        textTransform: "none",
                                        fontSize: "18px",
                                        height: "max-content",
                                        padding: "0 20px",
                                    }} />
                            }
                        </Box>
                        {!!stages.length &&
                            <Box sx={{ margin: "20px 0" }}>
                                <RaterButton
                                    title={texts.addCompany.submitBtn}
                                    disabled={!isValid}
                                    type="submit"
                                    customStyles={{
                                        fontSize: "24px",
                                        textTransform: "none",
                                    }}
                                />
                            </Box>
                        }
                    </>
                }
            </Box >
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    ".MuiPaper-root": {
                        borderRadius: "15px",
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" sx={{ mr: 2, fontSize: theme.font.size.regular }}>
                    {texts.addCompany.modal.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {texts.addCompany.modal.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center", marginBottom: "15px" }}>
                    <RaterButton
                        size="small"
                        clickHandler={handleOpenCompany}
                        title={texts.addCompany.modal.openProfileBtn}
                        customStyles={{ textTransform: "none" }}
                    />
                    <RaterButton
                        size="small"
                        clickHandler={handleClose}
                        title={texts.addCompany.modal.closeBtn}
                        customStyles={{ textTransform: "none" }}
                    />
                </DialogActions>
            </Dialog>
            <Dialog
                open={isExistsCompaniesModalOpen}
                onClose={handleCloseExistsCompaniesModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    ".MuiPaper-root": {
                        borderRadius: "15px",
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" sx={{ mr: 2, fontSize: theme.font.size.regular }}>
                    {texts.addCompany.modal.nameExistsTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {texts.addCompany.modal.nameExistsContent}
                    </DialogContentText>
                </DialogContent>
                {!!existNameCompanies.length &&
                    <Box>
                        {existNameCompanies.map((company: CompanyProps) => {
                            return (
                                <Link
                                    component={RouterLink}
                                    to={`${ROUTES.company}/${company.id}`}
                                    target="_blank"
                                    rel="noopener"
                                    sx={{
                                        color: theme.font.color.main,
                                        textDecorationColor: theme.font.color.main,
                                        transition: "all 0.3s linear",
                                        display: "block",
                                        padding: "3px 24px",
                                        "&:hover": {
                                            cursor: "pointer",
                                            color: theme.font.color.active,
                                            textDecorationColor: "transparent",
                                        }
                                    }}>
                                    {company.companyName}
                                </Link>)
                        })

                        }
                    </Box>
                }
                <DialogActions sx={{ justifyContent: "center", marginBottom: "15px" }}>
                    <RaterButton
                        size="small"
                        clickHandler={handleCloseExistsCompaniesModal}
                        title={texts.addCompany.modal.closeBtn}
                        customStyles={{ textTransform: "none" }}
                    />
                </DialogActions>
            </Dialog>
        </>
    )
}