import Box from "@mui/material/Box"
import { Sign } from "../components/Sign/Sign"
import { SignUpForm } from "../components/Sign/SignUpForm/SignUpForm"
import { SIGN_TYPES } from "../constants"

export const SignUp: React.FC = () => {
    return (
        <Box sx={{
            minHeight: "calc(100vh - 60px - 92px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <Sign type={SIGN_TYPES.UP}>
                <SignUpForm />
            </Sign>
        </Box>
    )
}