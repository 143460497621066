import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { AppDispatch, RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { displayNotificationModal } from '../../store/slices/UiSlice';


export const Notifications = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {showNotificationModal} = useSelector((state: RootState) => state.ui);

  const handleClose = () => {
    dispatch(displayNotificationModal({
      isOpen: false,
      messageType: '',
      message: '',
    }))
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: 'right' }}
      open={showNotificationModal.isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        variant="outlined"
        severity={showNotificationModal.messageType || undefined}
        sx={{
          bgcolor: 'background.paper',
        }}
      >
        <AlertTitle>{showNotificationModal.messageType.toUpperCase()}</AlertTitle>
        {showNotificationModal.message}
      </Alert>
    </Snackbar>
  );
};
