import styled from "styled-components";
import { theme } from "../../assets/theme";
import { Link } from "@mui/material";

export const CompanyContainer = styled("div")<{isCompanyNew?: boolean}>(({ isCompanyNew }) => ({
  display: "flex",
  justifyContent: "space-between",
  height: "100%",
  gap: "60px",
  padding: "40px 0",
  flex: 1,
  position: "relative",
  maxWidth: "1180px",
  "@media screen and (max-width: 992px)": {
    flexDirection: "column",
    flex: isCompanyNew ? 0 : 1,
    paddingBottom: isCompanyNew ? "20px": "40px",
  },
}));

export const CompanyMain = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  flex: 1,
});

export const CompanyInfo = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  gap: "15px",
});

export const CompanyInfoItem = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  background: theme.bg.second,
  borderRadius: "15px",
  padding: "15px",
  gap: "10px",
  width: "200px",
  flex: 1,
});

export const CompanyInfoLinks = styled(CompanyInfoItem)({
  width: "330px",
  flex: 1,
});

export const CompanyInfoParam = styled("div")({
  display: "flex",
  gap: "30px",
  textOverflow: "ellipsis",
});

export const CompanyInfoItemTitle = styled("div")({
  fontWeight: 600,
  flexBasis: "100px",
  whiteSpace: "nowrap",
});

export const CompanyInfoItemLink = styled(Link)({
  whiteSpace: "nowrap",
});

export const CompanyInfoItemLinkContainer = styled(Link)({
  overflow: "hidden",
  textOverflow: "ellipsis",
});

export const CompanyRating = styled("div")({
  alignSelf: "center",
  width: "330px",
  position: "fixed",
  left: "50%",
  transform: "translateX(280px)",
  top: "22vh",
  "@media screen and (max-width: 1200px)": {
    left: "auto",
    right: "30px",
    transform: "translateX(0)",
  },
  "@media screen and (max-width: 992px)": {
    top: "auto",
    right: "auto",
    position: "relative",
  },
});
