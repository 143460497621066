import { Box } from "@mui/material"
import { RaterButton } from "../components/UI/RaterButton/RaterButton"
import { RaterInput } from "../components/UI/RaterInput/RaterInput"
import { INPUT_IDS, ROUTES } from "../constants"
import { Controller, useForm } from "react-hook-form"
import { texts } from "../assets/texts"
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { theme } from "../assets/theme"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../store/store"
import { displayNotificationModal } from "../store/slices/UiSlice"
import { sendEmailToResetPass } from "../store/thunks/loginThunk"

const ResetPassEmailSchema = yup.object({
    email: yup.string().required(texts.add.requiredEmail).email(texts.add.invalidEmail),
}).required();

export const ResetPassEmailPage: React.FC = () => {
    const { control, formState: { errors, isValid } } = useForm({
        resolver: yupResolver(ResetPassEmailSchema),
        mode: "onBlur",
    });
    const dispatch = useDispatch<AppDispatch>();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const resetPassEmailData = {
            email: data.get('email'),
            link: `${process.env.REACT_APP_BASE_URL}${ROUTES.resetPassword}`,
        };

        const emailToResetPass = data.get('email');

        dispatch(sendEmailToResetPass({
            email: resetPassEmailData.email || "",
            link: resetPassEmailData.link || "",
        }))
            .then((res: any) => {
                if (res?.error) {
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: res?.error?.message,
                        messageType: 'error',
                    }));
                } else {
                    if (typeof emailToResetPass === "string") {
                        localStorage.setItem("emailToResetPass", emailToResetPass);
                    }
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: texts.notifications.checkEmailToResetPass,
                        messageType: 'success',
                    }));
                }
            });
    };

    return (
        <Box sx={{
            height: "calc(100vh - 60px - 92px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <Box component="form"
                onSubmit={handleSubmit}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "30px",
                    height: "350px",
                    backgroundColor: theme.bg.signLight,
                    borderRadius: "60px",
                    padding: "30px",
                }}
            >
                <Box sx={{
                    textAlign: "center",
                    fontSize: theme.font.size.medium,
                    marginBottom: "20px",
                }}>
                    {texts.forgotPassword.emailFormTitle}
                </Box>
                <Controller
                    name="email"
                    control={control}
                    render={({ field }) =>
                        <RaterInput
                            {...field}
                            type={INPUT_IDS.email}
                            placeholder={texts.sign.email}
                            errorText={errors?.email?.message}
                            marginBottomValue="20px"
                            required
                        />
                    }
                />
                <Box sx={{ margin: "0 auto" }}>
                    <RaterButton
                        title={texts.forgotPassword.submit}
                        disabled={!isValid}
                        type="submit"
                    />
                </Box>
            </Box>
        </Box>
    )
}