import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material"
import { CommentProps } from "../../types/candidate.types"
import { CommentsListContainer, CommentsListContent, CommentsListDate, CommentsListItem, CommentsListTitle, DeleteCommentButton, Stage, StageDateTime, StageDateTimeTitle, StageInfo, StageName, Stages } from "./CommentsList.styles"
import { RaterButton } from "../UI/RaterButton/RaterButton"
import { texts } from "../../assets/texts"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../store/store"
import { displayNotificationModal } from "../../store/slices/UiSlice"
import { deleteComment, getCandidateById, updateComment } from "../../store/thunks/candidateThunk"
import { useState } from "react"
import { theme } from "../../assets/theme"
import { Controller, useForm } from "react-hook-form"
import { RaterInput } from "../UI/RaterInput/RaterInput"
import { INPUT_IDS, PAGE_TYPES, ROLES } from "../../constants"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup";
import { localStorageGet } from "../../hooks/useLocalStorage"
import { formateDate } from "../../helpers/formateDate"
import { useLocation } from "react-router-dom"
import { deleteCompanyComment, getCompanyById, updateCompanyComment } from "../../store/thunks/companiesThunk"
import { CompanyCommentProps, StageProps } from "../../types/companies.types"
import { CommentsListProps } from "./types"
import Trash from '../../assets/icons/trash-solid.svg'

const noCyrillicRegex: RegExp = /^[^а-яА-ЯёЁ]+$/;

const DeclineCommentSchema = yup.object({
    comment: yup.string().required(texts.add.requiredComment).matches(noCyrillicRegex, texts.add.noCyrillic),
}).required();

export const CommentsList: React.FC<CommentsListProps> = ({ candidateById, companyById }) => {
    const location = useLocation();
    const commentsWithStages = location.pathname.includes(PAGE_TYPES.company);
    const isCandidateCommentsOwner = !!candidateById;
    const dispatch = useDispatch<AppDispatch>();
    const role = localStorageGet("role");

    const [openDeclineCommentModal, setOpenDeclineCommentModal] = useState(false);
    const [openDeleteCommentModal, setOpenDeleteCommentModal] = useState(false);
    const handleOpenDeclineCommentModal = () => setOpenDeclineCommentModal(true);
    const handleCloseDeclineCommentModal = () => setOpenDeclineCommentModal(false);
    const handleOpenDeleteCommentModal = () => setOpenDeleteCommentModal(true);
    const handleCloseDeleteCommentModal = () => setOpenDeleteCommentModal(false);

    const { control, formState: { errors, isValid }, reset } = useForm({
        resolver: yupResolver(DeclineCommentSchema),
        mode: "onBlur",
    });
    const [commentToDeclineId, setCommentToDeclineId] = useState("");

    const initialDeclineCommentFormValues = {
        comment: "",
    }

    const commentsListCopy: CommentProps[] = !!candidateById && candidateById.comments ? [...candidateById.comments] : [];
    const draftedComments: CommentProps[] = [];
    const nodraftedComments: CommentProps[] = [];
    commentsListCopy.forEach((comment) => {
        if (comment.draft) {
            draftedComments.push(comment)
        } else {
            nodraftedComments.push(comment)
        }
    })
    if (nodraftedComments.length > 1) {
        nodraftedComments.sort((a: CommentProps, b: CommentProps) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
    }
    const sortedComments: CommentProps[] = role === ROLES.admin ? [...draftedComments, ...nodraftedComments] : [...nodraftedComments];

    const companyCommentsListCopy: CompanyCommentProps[] = !!companyById && companyById?.comments ? [...companyById.comments] : [];
    const draftedCompanyComments: CompanyCommentProps[] = [];
    const nodraftedCompanyComments: CompanyCommentProps[] = [];

    companyCommentsListCopy.forEach((comment) => {
        if (comment.draft) {
            draftedCompanyComments.push(comment)
        } else {
            nodraftedCompanyComments.push(comment)
        }
    })

    if (nodraftedCompanyComments.length > 1) {
        nodraftedCompanyComments.sort((a: CompanyCommentProps, b: CompanyCommentProps) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
    }
    const sortedCompanyComments: CompanyCommentProps[] = role === ROLES.admin ? [...draftedCompanyComments, ...nodraftedCompanyComments] : [...nodraftedCompanyComments];

    const commentsToRender = isCandidateCommentsOwner ? sortedComments : sortedCompanyComments;

    const approveCommentHandler = async (comment: CommentProps) => {
        await dispatch(updateComment(comment)).then((res: any) => {
            if (res?.error) {
                dispatch(displayNotificationModal({
                    isOpen: true,
                    message: res?.error?.message,
                    messageType: 'error',
                }));
            } else {
                dispatch(displayNotificationModal({
                    isOpen: true,
                    message: texts.notifications.commentApproved,
                    messageType: 'success',
                }));
            }
        })
        if (candidateById) {
            dispatch(getCandidateById(candidateById.id))
        }
    }

    const approveCompanyCommentHandler = async (comment: CompanyCommentProps) => {
        await dispatch(updateCompanyComment(comment)).then((res: any) => {
            if (res?.error) {
                dispatch(displayNotificationModal({
                    isOpen: true,
                    message: res?.error?.message,
                    messageType: 'error',
                }));
            } else {
                dispatch(displayNotificationModal({
                    isOpen: true,
                    message: texts.notifications.companyCommentApproved,
                    messageType: 'success',
                }));
            }
        })
        if (companyById) {
            dispatch(getCompanyById(companyById.id))
        }
    }


    const handleDeclineCommentSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const DeclineCommentData = {
            id: commentToDeclineId,
            message: data.get('comment'),
        };

        if (candidateById) {
            dispatch(deleteComment(DeclineCommentData)).then((res: any) => {
                if (res?.error) {
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: res?.error?.message,
                        messageType: 'error',
                    }));
                } else {
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: texts.notifications.commentDeclinedSuccess,
                        messageType: 'success',
                    }));
                    reset(initialDeclineCommentFormValues);
                    handleCloseDeclineCommentModal();
                    dispatch(getCandidateById(candidateById.id))
                }
            })
        }

        if (companyById) {
            dispatch(deleteCompanyComment(DeclineCommentData)).then((res: any) => {
                if (res?.error) {
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: res?.error?.message,
                        messageType: 'error',
                    }));
                } else {
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: texts.notifications.commentDeclinedSuccess,
                        messageType: 'success',
                    }));
                    reset(initialDeclineCommentFormValues);
                    handleCloseDeclineCommentModal();
                    dispatch(getCompanyById(companyById.id))
                }
            })
        }
    };

    const handleRemoveComment = () => {
        const DeclineCommentData = {
            id: commentToDeclineId,
            message: "",
        };
        if (candidateById) {
            dispatch(deleteComment(DeclineCommentData)).then((res: any) => {
                if (res?.error) {
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: res?.error?.message,
                        messageType: 'error',
                    }));
                } else {
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: texts.notifications.successDeleteComment,
                        messageType: 'success',
                    }));
                    handleCloseDeleteCommentModal();
                    dispatch(getCandidateById(candidateById.id))
                }
            })
        }

        if (companyById) {
            dispatch(deleteCompanyComment(DeclineCommentData)).then((res: any) => {
                if (res?.error) {
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: res?.error?.message,
                        messageType: 'error',
                    }));
                } else {
                    dispatch(displayNotificationModal({
                        isOpen: true,
                        message: texts.notifications.successDeleteComment,
                        messageType: 'success',
                    }));
                    handleCloseDeleteCommentModal();
                    dispatch(getCompanyById(companyById.id))
                }
            })
        }
    }

    return (

        <CommentsListContainer>
            {!!commentsToRender.length && commentsToRender?.map((comment: any) => {
                return (
                    <Box key={comment.id}>
                        <CommentsListItem moderating={comment.draft}>
                            <Box sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "10px",
                            }}>
                                <CommentsListTitle>
                                    {commentsWithStages ? comment.userName : comment.company}
                                </CommentsListTitle>
                                <CommentsListDate>{formateDate(comment.createdAt)}</CommentsListDate>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                                <CommentsListContent>
                                    {comment.text}
                                </CommentsListContent>
                                {role === ROLES.admin &&
                                    <DeleteCommentButton
                                        onClick={() => {
                                            setCommentToDeclineId(comment.id);
                                            handleOpenDeleteCommentModal();
                                        }}
                                    >
                                        <img src={Trash} alt="Trash can" />
                                    </DeleteCommentButton>
                                }
                            </Box>
                            {comment.stages && !!comment.stages.length &&
                                <Stages>
                                    {comment.stages.map((stage: StageProps) => (
                                        <Stage>
                                            <StageInfo>
                                                <StageName>{stage.stageName}</StageName>
                                                <Box>{stage.shortDescr}</Box>
                                                <Box>{stage.description}</Box>
                                            </StageInfo>
                                            {(!!stage.days || !!stage.hours) &&
                                                <StageDateTime>
                                                    <StageDateTimeTitle>{texts.companies.stages.dateTimeTitle}</StageDateTimeTitle>
                                                    {!!stage.days && `${stage.days} days `}
                                                    {!!stage.hours && `${stage.hours} hours`}
                                                </StageDateTime>
                                            }
                                        </Stage>
                                    ))}
                                </Stages>
                            }
                            {
                                isCandidateCommentsOwner && comment.draft &&
                                sortedComments.length !== 1 &&
                                <Box sx={{ display: "flex", marginTop: "15px", gap: "20px" }}>
                                    <RaterButton size="small" clickHandler={() => approveCommentHandler(comment)} title={texts.candidate.moderation.commentApprove} />
                                    <RaterButton size="small" clickHandler={() => {
                                        setCommentToDeclineId(comment.id);
                                        handleOpenDeclineCommentModal();
                                    }} title={texts.candidate.moderation.commentDecline} />
                                </Box>
                            }
                            {
                                !isCandidateCommentsOwner &&
                                comment.draft &&
                                sortedCompanyComments.length !== 1 &&
                                <Box sx={{ display: "flex", marginTop: "15px", gap: "20px" }}>
                                    <RaterButton size="small" clickHandler={() => { approveCompanyCommentHandler(comment) }} title={texts.candidate.moderation.commentApprove} />
                                    <RaterButton size="small" clickHandler={() => {
                                        setCommentToDeclineId(comment.id);
                                        handleOpenDeclineCommentModal();
                                    }} title={texts.candidate.moderation.commentDecline} />
                                </Box>
                            }
                        </CommentsListItem>
                    </Box>
                )
            })}

            <Dialog
                open={openDeclineCommentModal}
                onClose={handleCloseDeclineCommentModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    ".MuiPaper-root": {
                        borderRadius: "15px",
                    }
                }}
            >
                <Box
                    component="form"
                    onSubmit={handleDeclineCommentSubmit}
                >
                    <DialogTitle id="alert-dialog-title" sx={{ mr: 2, fontSize: theme.font.size.regular }}>
                        {texts.candidate.moderation.whyDeclineComment}
                    </DialogTitle>
                    <DialogContent>
                        <Controller
                            name="comment"
                            control={control}
                            render={({ field }) =>
                                <RaterInput
                                    {...field}
                                    type={INPUT_IDS.comment}
                                    placeholder={texts.candidate.moderation.addExplanations}
                                    errorText={errors?.comment?.message}
                                    required
                                    customStyles={{
                                        minHeight: "40px",
                                    }}
                                />
                            }
                        />

                    </DialogContent>
                    <DialogActions sx={{ justifyContent: "center", marginBottom: "15px" }}>
                        <RaterButton size="small"
                            type="submit"
                            title={texts.candidate.moderation.sendExplanations}
                            disabled={!isValid}
                        />
                        <RaterButton
                            size="small"
                            clickHandler={() => {
                                reset(initialDeclineCommentFormValues);
                                handleCloseDeclineCommentModal();
                            }}
                            title={texts.candidate.moderation.closeModal}
                        />
                    </DialogActions>
                </Box>
            </Dialog >

            <Dialog
                open={openDeleteCommentModal}
                onClose={handleCloseDeleteCommentModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    ".MuiPaper-root": {
                        borderRadius: "15px",
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" sx={{ mr: 2, fontSize: theme.font.size.regular }}>
                    {texts.notifications.deleteCommentQuestion}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {texts.notifications.deleteCommentExplanations}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center", marginBottom: "15px" }}>
                    <RaterButton size="small" clickHandler={handleRemoveComment} title={texts.notifications.deleteCommentRemoveBtn} />
                    <RaterButton size="small" clickHandler={handleCloseDeleteCommentModal} title={texts.notifications.deleteCommentCancelBtn} />
                </DialogActions>
            </Dialog>
        </CommentsListContainer >

    )
}
