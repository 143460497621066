import { useDispatch, useSelector } from "react-redux";
import { texts } from "../../assets/texts"
import { AppDispatch, RootState } from "../../store/store";
import { RaterRatingCommentsAmount, RaterRatingContainer, RaterRatingProgress, RaterRatingRow, RaterRatingRowTitle, RaterRatingRows, RaterRatingTitle } from "./RaterRating.styles"
import { RaterRatingProps } from "./type"
import { setRating } from "../../store/slices/CandidateSlice";
import { Box, Divider, Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { theme } from "../../assets/theme";
import { RATING_TYPES } from "../../constants";
import { setCompanyRating } from "../../store/slices/CompaniesSlice";

export const RaterRating: React.FC<RaterRatingProps> = ({ rows, ratingParams, readOnly, isCommentType, commentsAmount, ratingType }) => {
    const { rating: ratingFromStore } = useSelector((state: RootState) => ratingType === RATING_TYPES.company ? state.companies : state.candidates);
    const currentRating = ratingParams || ratingFromStore;

    const dispatch = useDispatch<AppDispatch>();

    return (
        <RaterRatingContainer isCommentType={!!isCommentType}>
            {!isCommentType &&
                <RaterRatingTitle>
                    {texts.add.rating.title}
                </RaterRatingTitle>}
            {commentsAmount &&
                <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flex: 1,
                    marginBottom: "25px",
                }}>
                    <Box sx={{ display: "flex", gap: "5px" }}>
                        <RaterRatingRowTitle >{texts.add.rating.commentsAmount}</RaterRatingRowTitle>
                        <Tooltip
                            title={texts.add.rating.feedbacksTooltip}
                            placement="right"
                            arrow
                            PopperProps={{
                                sx: {
                                    "& .MuiTooltip-tooltip": {
                                        backgroundColor: theme.rating.active,
                                        color: theme.font.color.white,
                                        padding: "10px",
                                    },
                                    "& .MuiTooltip-arrow": {
                                        color: theme.rating.active,
                                    }
                                }
                            }}
                        >
                            <InfoIcon fontSize="small" sx={{ color: theme.bg.dark, transform: "translateY(1px)" }} />
                        </Tooltip>
                    </Box>
                    <RaterRatingCommentsAmount>{commentsAmount}</RaterRatingCommentsAmount>
                </Box>
            }
            <RaterRatingRows isCommentType={!!isCommentType}>
                {rows.map((row) => {
                    return (
                        <>
                            <RaterRatingRow key={row.id}>
                                <Box sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    flex: 1,
                                    gap: "10px",
                                }}>
                                    <RaterRatingRowTitle >{row.title}</RaterRatingRowTitle>
                                    <RaterRatingProgress
                                        precision={row.id === "total" ? 0.5 : 1}
                                        readOnly={readOnly}
                                        name={row.id}
                                        value={currentRating[row.id as keyof typeof currentRating]}
                                        onChange={(event: React.SyntheticEvent, newValue: number | null) => {
                                            if (ratingType === RATING_TYPES.company) {
                                                dispatch(setCompanyRating({ id: row.id, value: newValue }));
                                            } else {
                                                dispatch(setRating({ id: row.id, value: newValue }));
                                            }

                                        }} />
                                </Box>
                                {row.id === "total" &&
                                    <Box sx={{ width: "100%", marginTop: "25px" }}>
                                        <Divider absolute />
                                    </Box>
                                }
                            </RaterRatingRow>
                        </>
                    )
                })
                }
            </RaterRatingRows>
        </RaterRatingContainer>
    )
}