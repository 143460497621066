import { createAsyncThunk } from "@reduxjs/toolkit";
import { REQUEST_ROUTES } from "../../constants";
import axios from "axios";
import { rejectWithValue } from "../helpers";

export const inviteThunk = createAsyncThunk(
  REQUEST_ROUTES.invite,
  async ({
    email,
    link,
  }: {
    email: FormDataEntryValue | null;
    link: string;
  }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}${REQUEST_ROUTES.invite}`,
        { email, link },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
