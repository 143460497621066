export const SIGN_TYPES = {
  IN: "in",
  UP: "up",
};

export const INPUT_TYPES = {
  name: "name",
  email: "email",
  password: "password",
  search: "search",
  text: "text",
};

export const INPUT_IDS = {
  name: "name",
  companyName: "companyName",
  email: "email",
  password: "password",
  confirmPassword: "confirmPassword",
  search: "search",
  position: "position",
  company: "company",
  urlLinkedIn: "urlLinkedIn",
  urlFacebook: "urlFacebook",
  urlGitHub: "urlGitHub",
  comment: "comment",
  douUrl: "douUrl",
  djinniUrl: "djinniUrl",
  linkedinUrl: "linkedinUrl",
  website: "website",
  stageName: "stageName",
  stageShortDescription: "stageShortDescription",
  stageDescription: "stageDescription",
  days: "days",
  hours: "hours",
};

export const ROUTES: Record<string, string> = {
  home: "/",
  signUp: "/sign-up",
  signIn: "/sign-in",
  candidates: "/candidates",
  adminCandidates: "/admin/candidates",
  users: "/admin/users",
  companies: "/companies",
  adminCompanies: "/admin/companies",
  moderation: "/moderation",
  candidate: "/candidate",
  company: "/company",
  addCompany: "/add-company",
  addCandidate: "/add-candidate",
  resetPassword: "/reset-password",
  resetPassEmail: "/reset-pass-email",
  notFound: "*",
};

export const REQUEST_ROUTES: Record<string, string> = {
  logIn: "/auth/login",
  logOut: "/auth/logout",
  signUp: "/auth/signup",
  invite: "/auth/send-invite",
  sendChangePassword: "/auth/send-change-password",
  changePassword: "/auth/password-change",
  users: "/users",
  candidates: "/candidates",
  candidatesSearch: "/candidates/search",
  candidatesSearchLinkedIn: "/candidates/linkedin",
  candidatesSearchGithub: "/candidates/github",
  comments: "/comments",
  companies: "/companies",
  companiesSearch: "/companies/search",
  companiesSearchName: "/companies/company-name",
  companiesSearchSite: "/companies/site",
  companiesSearchLinkedIn: "/companies/linkedin",
  refreshToken: "/auth/refresh",
  companyComments: "/company-comments",
};

export const thunkIds: Record<string, string> = {
  addCandidate: "/addCandidate",
  deleteCandidate: "/deleteCandidate",
  updateCandidate: "/updateCandidate",
  candidatebyId: "/candidatebyId",
  createComment: "/createComment",
  approveComment: "/approveComment",
  declineComment: "/declineComment",
  addCompany: "/addCompany",
  deleteCompany: "/deleteCompany",
  companyById: "/companyById",
  createCompanyComment: "/createCompanyComment",
  approveCompanyComment: "/approveCompanyComment",
  declineCompanyComment: "/declineCompanyComment",
};

export const ROLES = {
  admin: "ADMIN",
};

export const RATING_IDS: string[] = [
  "communication",
  "coding",
  "independence",
  "teamwork",
  "responsibility",
];

export const COMPANY_RATING_IDS: string[] = [
  "communication",
  "timeManagement",
  "flexibility",
  "expertise",
  "quality",
];

export const TABLE_TYPES = {
  candidates: "candidates",
  companies: "companies",
  users: "users",
}

export const SEARCH_TYPES = {
  candidates: "candidates",
  companies: "companies",
}

export const BTN_ICON_TYPES = {
  add: "add",
}

export const RATING_TYPES = {
  company: "company",
}

export const PAGE_TYPES = {
  company: "company",
}
